import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Alert,
  Checkbox,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Droppable } from "react-beautiful-dnd";
import {
  deleteGraduationMapping,
  updateGraduationMapping,
} from "../../../../service/graduationMappingService";
import * as styles from "./PlanRequirement.styles";

export default function RequirementDetails({
  requirement,
  creditsCourses,
  setCreditsCourses,
  loadingMappings = {},
  errorMappings = {},
  setErrorMappings,
  warningMappings = {},
  setWarningMappings,
  successMappings = {},
  setSuccessMappings,
  hasManagePermission,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [mandatoryMappings, setMandatoryMappings] = useState([]);
  const [mandatoryLoading, setMandatoryLoading] = useState([]);

  const coursesFiltered = (creditId) =>
    creditsCourses.filter((c) => c.creditId === creditId);

  const handleDeleteClick = (creditId, courseId, mappingId, courseName) => {
    setDeleteInfo({ creditId, courseId, mappingId, courseName });
    setOpenDialog(true);
    setDeleteError(null);
  };

  const handleCloseDialog = () => {
    if (isDeleting) return;
    setOpenDialog(false);
    setDeleteInfo(null);
    setDeleteError(null);
    setErrorMappings({});
  };

  const deleteCreditCourse = async () => {
    if (!deleteInfo) return;

    const { creditId, courseId, mappingId } = deleteInfo;
    setIsDeleting(true);
    setDeleteError(null);

    try {
      await deleteGraduationMapping(creditId, mappingId);
      setCreditsCourses((prev) =>
        prev.filter((c) => c.creditId !== creditId || c.course_id !== courseId)
      );
      handleCloseDialog();
    } catch (error) {
      console.error("Error deleting graduation mapping:", error);
      setDeleteError(
        "An error occurred while deleting the mapping. Please try again."
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const isMandatory = (mappingId) => mandatoryMappings.includes(mappingId);

  const loadingMandatory = (mappingId) => mandatoryLoading.includes(mappingId);

  const handleMandatory = (creditId, event) => {
    const { value: mappingId, checked } = event.target;

    setMandatoryLoading((prev) => [...prev, Number(mappingId)]);
    setErrorMappings({});

    updateGraduationMapping(creditId, mappingId, {
      graduation_mapping: { mandatory: checked },
    })
      .then(() => {
        if (checked) {
          setMandatoryMappings((prev) => [...prev, Number(mappingId)]);
        } else {
          setMandatoryMappings((prev) =>
            prev.filter((m) => m !== Number(mappingId))
          );
        }

        setMandatoryLoading([]);
      })
      .catch(() => {
        setErrorMappings((prev) => ({
          ...prev,
          [creditId]: true,
        }));

        setMandatoryLoading([]);
      });
  };

  useEffect(() => {
    setWarningMappings({});
  }, [creditsCourses, setWarningMappings, setSuccessMappings]);

  useEffect(() => {
    const mandatory = requirement.graduation_credits
      .map((credit) =>
        credit.graduation_mappings.filter((m) => m.mandatory).map((m) => m.id)
      )
      .flat();

    setMandatoryMappings(mandatory);
  }, [requirement]);

  const renderCreditCourses = (creditId, isDraggingOver) => {
    const fCourses = coursesFiltered(creditId);

    if (fCourses.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Typography
              sx={{
                textAlign: "center",
                border: "3px dashed #ededed",
                p: 4,
                mt: 2,
                borderRadius: "20px",
                color: isDraggingOver ? "black" : "#999",
                borderColor: isDraggingOver ? "#999" : undefined,
              }}
            >
              {isDraggingOver ? (
                <>
                  Drop course here
                  <br />
                  to add it to this subject.
                </>
              ) : (
                <>
                  Search courses on the right
                  <br />
                  then drag and drop it here.
                </>
              )}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        <TableRow>
          <TableCell>
            <b>Course</b>
          </TableCell>
          <TableCell>
            <b>Description</b>
          </TableCell>
          <TableCell>
            <b>Credits</b>
          </TableCell>
          <TableCell align="center">
            <b>Mandatory</b>
          </TableCell>
          <TableCell />
        </TableRow>

        {fCourses.map((course) => (
          <TableRow
            hover={!isDraggingOver}
            key={course.id}
            sx={{ lineHeight: "24px" }}
          >
            <TableCell sx={{ width: "30%" }}>
              <b>{course.course_name}</b>
            </TableCell>
            <TableCell sx={{ width: "30%" }}>{course.description}</TableCell>
            <TableCell sx={{ width: "15%" }}>
              {course.credits_pluralized}
            </TableCell>
            <TableCell sx={{ width: "15%" }} align="center">
              {loadingMandatory(course.id) ? (
                <CircularProgress size={36} />
              ) : (
                <Checkbox
                  value={course.id}
                  checked={isMandatory(course.id)}
                  onChange={(e) => handleMandatory(creditId, e)}
                />
              )}
            </TableCell>
            <TableCell sx={{ textAlign: "right", width: "10%" }}>
              {hasManagePermission && (
                <IconButton
                  size="small"
                  onClick={() =>
                    handleDeleteClick(
                      creditId,
                      course.course_id,
                      course.id,
                      course.course_name
                    )
                  }
                  disabled={loadingMappings[creditId]}
                >
                  <DeleteIcon size="small" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderMappingStatus = (creditId) => {
    if (loadingMappings[creditId]) {
      return <CircularProgress size={20} />;
    }
    if (errorMappings[creditId]) {
      return (
        <Typography
          variant="body2"
          sx={{
            color: "error.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          Error saving mapping. Please try again.
          <ErrorIcon color="error" sx={{ ml: 2 }} />
        </Typography>
      );
    }
    if (warningMappings[creditId]) {
      return (
        <Typography
          variant="body2"
          sx={{
            color: "warning.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          Course already mapped
          <WarningIcon color="warning" sx={{ ml: 2 }} />
        </Typography>
      );
    }
    if (successMappings[creditId]) {
      return (
        <Typography
          variant="body2"
          sx={{
            color: "success.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          Course successfully added
          <CheckCircleIcon color="success" sx={{ ml: 2 }} />
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      {requirement.graduation_credits.map((credit) => (
        <Droppable droppableId={`${credit.id}`} key={credit.id}>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                ...styles.droppableBox,
                borderColor: snapshot.isDraggingOver ? "black" : "#ededed",
              }}
            >
              <TableContainer sx={styles.tableContainerStyles}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Typography variant="h5">
                                  {credit.course_name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography sx={styles.creditsTypography}>
                                  ({credit.credits_pluralized})
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>{renderMappingStatus(credit.id)}</Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderCreditCourses(credit.id, snapshot.isDraggingOver)}
                  </TableBody>
                </Table>
              </TableContainer>

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      ))}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the graduation mapping for{" "}
            {deleteInfo?.courseName}?
          </DialogContentText>
          {isDeleting && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          {deleteError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {deleteError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={isDeleting}>
            Cancel
          </Button>
          <Button onClick={deleteCreditCourse} disabled={isDeleting} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
