import theme from "../../../utils";

const termGradeSkillCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const termGradeSkillCellName = {
  ...termGradeSkillCell,
  fontSize: "20px",
};

const termGradeSkillCellGrade = {
  ...termGradeSkillCell,
  px: 0,
  width: "100px",
  height: "70px",
  borderRight: "1px solid black",
  backgroundColor: "white",
  "& :hover": {
    cursor: "pointer",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const termGradeSkillCellSquare = {
  "&:focus-within": {
    border: `6px solid ${theme.palette.cell.selected}`,
  },
  height: "100%",
  width: "100%",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const termGradeSkillCellDisabled = {
  ...termGradeSkillCellGrade,
  backgroundColor: "#D9D9D9",
  "& :hover": {
    cursor: "default",
    backgroundColor: "#D9D9D9",
  },
};

const termGradeSkillCellSquareGray = {
  ...termGradeSkillCellSquare,
  backgroundColor: "#D9D9D9",
  cursor: "default",
  height: "100%",
  padding: 0,
};

const termGradeTableInput = {
  textAlign: "center",
  color: "black",
  textTransform: "uppercase",
};

const stickyCell = {
  height: "90px",
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 98,
  py: 0,
  width: "100vw",
  display: "table-cell",
  verticalAlign: "middle",
};

export {
  termGradeSkillCell,
  termGradeSkillCellName,
  termGradeSkillCellGrade,
  termGradeSkillCellSquare,
  termGradeTableInput,
  termGradeSkillCellDisabled,
  termGradeSkillCellSquareGray,
  stickyCell,
};
