import axios from "../utils/axios-util";

const endpoint = "/api/graduation_plans";

const createGraduationPlan = (p) => axios.post(endpoint, p);
const fetchGraduationPlans = (p) => axios.get(endpoint, p);
const fetchGraduationPlan = (id) => axios.get(`${endpoint}/${id}`);
const updateGraduationPlan = (id, p) => axios.put(`${endpoint}/${id}`, p);
const deleteGraduationPlan = (id) => axios.delete(`${endpoint}/${id}`);

export {
  createGraduationPlan,
  fetchGraduationPlans,
  fetchGraduationPlan,
  updateGraduationPlan,
  deleteGraduationPlan,
};
