import axios from "../utils/axios-util";

const endpoint = (planId) => `/api/graduation_plans/${planId}/requirements`;

const createGraduationRequirement = (planId, p) =>
  axios.post(endpoint(planId), p);

const fetchGraduationRequirements = (planId, p) =>
  axios.get(endpoint(planId), p);

const fetchGraduationRequirement = (planId, id) =>
  axios.get(`${endpoint(planId)}/${id}`);

const updateGraduationRequirement = (planId, id, p) =>
  axios.put(`${endpoint(planId)}/${id}`, p);

const deleteGraduationRequirement = (planId, id) =>
  axios.delete(`${endpoint(planId)}/${id}`);

export {
  createGraduationRequirement,
  fetchGraduationRequirements,
  fetchGraduationRequirement,
  updateGraduationRequirement,
  deleteGraduationRequirement,
};
