import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CommonContext } from "../../../context/CommonContext";
import PlanRequirementForm from "./Requirements/PlanRequirementForm";
import { fetchGraduationPlan } from "../../../service/graduationPlanService";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { loader, rightDrawerContainer } from "../../sharedStyles";
import graduationPlansContainer from "./GraduationPlans.style";

function RowMenu({ requirementId, handleEdit }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit(requirementId);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleEditClick}>Edit requirements</MenuItem>
      </Menu>
    </>
  );
}

export default function SchoolGraduationPlan() {
  const { hasPermission } = useContext(PermissionsContext);
  const navigate = useNavigate();
  const commonContext = useContext(CommonContext);
  const params = useParams();
  const planId = params.id;
  const schoolId = params.school_id;
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editRequirementId, setEditRequirementId] = useState(null);

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.GRADUATION_PLANS,
    PERMISSION_TYPES.MANAGE
  );

  const fetchPlan = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!loading) setLoading(true);
    fetchGraduationPlan(planId).then((response) => {
      setPlan(response.data);
    });
  };

  const handleEdit = (requirementId) => {
    setEditRequirementId(requirementId);
  };

  const goBack = () => {
    navigate(`/school/${schoolId}/school/graduation-plans`);
  };

  const navigateToRequirement = (requirementId) => {
    navigate(
      `/school/${schoolId}/school/graduation-plans/${planId}/requirements/${requirementId}`
    );
  };

  useEffect(() => {
    if (commonContext.processStarted) fetchPlan();
  }, [commonContext.processStarted]);

  useEffect(() => {
    if (plan) {
      setLoading(false);
      commonContext.cleanFireProcessStarted();
    } else {
      commonContext.fireProcessStarted();
    }
  }, [plan]);

  if (loading) return <CircularProgress sx={loader} size={100} />;

  if (!plan) return <span>Error loading plan.</span>;

  return (
    <>
      <Box sx={graduationPlansContainer}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h2" sx={{ mt: 3, mb: 1 }}>
              {plan.name}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="info"
              size="small"
              onClick={goBack}
              sx={{ textTransform: "capitalize" }}
            >
              Return to Graduation Plans
            </Button>
          </Grid>
        </Grid>

        <Box className="plan-container">
          <Typography variant="h5">{plan.description}</Typography>

          <TableContainer sx={{ mt: 3 }} className="bordered">
            <Table>
              <TableBody>
                {plan.graduation_requirements.length > 0 ? (
                  plan.graduation_requirements.map((r) => (
                    <TableRow hover key={r.id}>
                      <TableCell>{r.name}</TableCell>
                      <TableCell align="center">{r.required_credits}</TableCell>
                      <TableCell>{r.details}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => navigateToRequirement(r.id)}
                        >
                          Map Courses
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        {hasManagePermission && (
                          <RowMenu
                            requirementId={r.id}
                            handleEdit={handleEdit}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box sx={graduationPlansContainer}>
                    <Typography
                      variant="h5"
                      sx={{
                        my: 8,
                        textAlign: "center",
                        color: "disabled.main",
                      }}
                    >
                      No subjects added yet.
                    </Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Drawer anchor="right" open={Boolean(editRequirementId)}>
        <Box sx={rightDrawerContainer}>
          <PlanRequirementForm
            schoolId={schoolId}
            planId={planId}
            requirementId={editRequirementId}
            handleDrawer={() => setEditRequirementId(null)}
          />
        </Box>
      </Drawer>
    </>
  );
}
