import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  attendanceSummaryTable,
  attendanceSummaryTableCell,
  attendanceSummaryCode,
  attendanceSummaryParagraph,
  attendanceSummaryCount,
  attendanceSummaryTableHeaderEx,
  attendanceSummaryTableHeaderLabel,
  attendanceSummaryTableHeaderLabelP,
  attendanceSummaryBorder,
  attendanceSummaryTableCellBorder,
  attendanceSummaryTableHeaderExB,
  attendanceSummaryCodeB,
  attendanceSummaryCountB,
  attendanceSummaryName,
} from "./AttendanceSummary.styles";

export default function AttendanceSummaryTable({ summary }) {
  return (
    <TableContainer>
      <Table sx={attendanceSummaryTable} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left" />
            <TableCell sx={attendanceSummaryTableHeaderLabelP} align="center">
              PRESENT
            </TableCell>
            <TableCell sx={attendanceSummaryTableHeaderLabel} align="center">
              ABSENCE
            </TableCell>
            <TableCell sx={attendanceSummaryTableHeaderLabel} align="center">
              TARDY
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              <Typography sx={attendanceSummaryParagraph}>
                {summary?.summary_name}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell sx={attendanceSummaryTableHeaderLabelP} />
            <TableCell sx={attendanceSummaryTableHeaderLabel}>
              <Stack direction="row">
                <Typography sx={attendanceSummaryTableHeaderExB}>
                  EXCUSED
                </Typography>
                <Typography sx={attendanceSummaryTableHeaderEx}>
                  UNEXCUSED
                </Typography>
              </Stack>
            </TableCell>
            <TableCell sx={attendanceSummaryTableHeaderLabel}>
              <Stack direction="row">
                <Typography sx={attendanceSummaryTableHeaderExB}>
                  EXCUSED
                </Typography>
                <Typography sx={attendanceSummaryTableHeaderEx}>
                  UNEXCUSED
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left" />
            <TableCell sx={attendanceSummaryBorder}>
              <Stack direction="row">
                <Typography sx={attendanceSummaryCodeB}>AM</Typography>
                <Typography sx={attendanceSummaryCode}>PM</Typography>
              </Stack>
            </TableCell>
            <TableCell sx={attendanceSummaryBorder}>
              <Stack direction="row">
                <Stack direction="row">
                  <Typography sx={attendanceSummaryCodeB}>AM</Typography>
                  <Typography sx={attendanceSummaryCodeB}>PM</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={attendanceSummaryCodeB}>AM</Typography>
                  <Typography sx={attendanceSummaryCode}>PM</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell sx={attendanceSummaryBorder}>
              <Stack direction="row">
                <Stack direction="row">
                  <Typography sx={attendanceSummaryCodeB}>AM</Typography>
                  <Typography sx={attendanceSummaryCodeB}>PM</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={attendanceSummaryCodeB}>AM</Typography>
                  <Typography sx={attendanceSummaryCode}>PM</Typography>
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summary?.students.map((student) => (
            <TableRow
              key={student.id}
              hover
              sx={{ borderBottom: "1px solid black" }}
            >
              <TableCell
                sx={attendanceSummaryTableCell}
                align="left"
                scope="student"
              >
                <Typography
                  sx={attendanceSummaryName}
                >{`${student.last_name}, ${student.first_name}`}</Typography>
              </TableCell>
              <TableCell sx={attendanceSummaryTableCell}>
                <Typography sx={attendanceSummaryParagraph}>
                  {student.grade === "TK" ||
                  student.grade === "K" ||
                  student.grade === "PK"
                    ? student.grade
                    : `${student.grade}th grade`}{" "}
                  / {student.homeroom_teacher || "-"}
                </Typography>
              </TableCell>
              <TableCell sx={attendanceSummaryTableCellBorder}>
                <Stack direction="row">
                  <Typography sx={attendanceSummaryCountB}>
                    {student?.attendances?.present.am}
                  </Typography>
                  <Typography sx={attendanceSummaryCount}>
                    {student?.attendances?.present.pm}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={attendanceSummaryTableCellBorder}>
                <Stack direction="row">
                  <Stack direction="row">
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.absent.excused.am}
                    </Typography>
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.absent.excused.pm}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.absent.unexcused.am}
                    </Typography>
                    <Typography sx={attendanceSummaryCount}>
                      {student?.attendances?.absent.unexcused.pm}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell sx={attendanceSummaryTableCellBorder}>
                <Stack direction="row">
                  <Stack direction="row">
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.tardy.excused.am}
                    </Typography>
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.tardy.excused.pm}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography sx={attendanceSummaryCountB}>
                      {student?.attendances?.tardy.unexcused.am}
                    </Typography>
                    <Typography sx={attendanceSummaryCount}>
                      {student?.attendances?.tardy.unexcused.pm}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
