import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import schoolsService from "../../service/schoolsService";
import { font17, mt35 } from "../sharedStyles";
import SnackBarNotification from "../SnackBarNotification";

export default function PeriodAttendanceSettings({ managePermission }) {
  const params = useParams();
  const schoolId = params.school_id;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [periodAttendance, setPeriodAttendance] = useState(false);
  const [periodAttendanceGrades, setPeriodAttendanceGrades] = useState([]);
  const [halfDayMinutes, setHalfDayMinutes] = useState(0);
  const [fullDayMinutes, setFullDayMinutes] = useState(0);

  const getSchoolInfo = async () => {
    try {
      const response = await schoolsService.fetchSchool(schoolId);
      setPeriodAttendance(response?.data?.period_attendance);
      setPeriodAttendanceGrades(response?.data?.period_attendance_grades);
      setHalfDayMinutes(response?.data?.half_day_minutes);
      setFullDayMinutes(response?.data?.full_day_minutes);
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const updateSchoolPeriodAttendance = async (pa, hdm, fdm, pag) => {
    try {
      const response = await schoolsService.updateSchoolPeriodAttendance(
        schoolId,
        pa,
        hdm,
        fdm,
        pag
      );
      if (response) {
        setSnackBarOpen({
          open: true,
          message: "Saved successfully",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getSchoolInfo();
  }, []);

  return (
    <Grid sx={mt35} container columnSpacing={2}>
      <Grid
        item
        sm={12}
        style={{ display: "flex", paddingLeft: 0, paddingBottom: "20px" }}
      >
        <Checkbox
          disabled={!managePermission}
          checked={periodAttendance}
          onChange={(e) => {
            setPeriodAttendance(e.target.checked);
            updateSchoolPeriodAttendance(
              e.target.checked,
              halfDayMinutes,
              fullDayMinutes,
              periodAttendanceGrades
            );
          }}
        />
        <Typography sx={{ font17, alignContent: "center" }}>
          Use period attendance for day attendance calculation
        </Typography>
      </Grid>
      {periodAttendance && (
        <>
          <Grid item sm={12} sx={{ pb: "10px" }}>
            <FormGroup
              sx={{ display: "inline" }}
              onClick={(e) => {
                const grade = e.target.value;
                let grades = periodAttendanceGrades;
                if (e.target.checked) {
                  grades.push(grade);
                } else {
                  grades = grades.filter((item) => item !== grade);
                }
                setPeriodAttendanceGrades(grades);

                updateSchoolPeriodAttendance(
                  periodAttendance,
                  halfDayMinutes,
                  fullDayMinutes,
                  grades
                );
              }}
            >
              {["TK", "K", "1", "2", "3", "4", "5", "6", "7", "8"].map(
                (item) => (
                  <FormControlLabel
                    value={item}
                    control={
                      <Checkbox
                        defaultChecked={periodAttendanceGrades.includes(item)}
                        key={item}
                      />
                    }
                    label={item}
                    key={item}
                  />
                )
              )}
            </FormGroup>
          </Grid>
          <Grid item sm={1} sx={{ paddingBottom: "20px" }}>
            <TextField
              id="full_day_minutes"
              fullWidth
              defaultValue={fullDayMinutes}
              disabled={!managePermission}
              onChange={(e) => {
                setFullDayMinutes(e.target.value);
                updateSchoolPeriodAttendance(
                  periodAttendance,
                  halfDayMinutes,
                  e.target.value,
                  periodAttendanceGrades
                );
              }}
            />
          </Grid>
          <Grid
            item
            sm={11}
            sx={{ alignContent: "center", paddingBottom: "20px" }}
          >
            <Typography>minutes to count as full day</Typography>
          </Grid>
          <Grid item sm={1}>
            <TextField
              id="half_day_minutes"
              fullWidth
              defaultValue={halfDayMinutes}
              disabled={!managePermission}
              onChange={(e) => {
                setHalfDayMinutes(e.target.value);
                updateSchoolPeriodAttendance(
                  periodAttendance,
                  e.target.value,
                  fullDayMinutes,
                  periodAttendanceGrades
                );
              }}
            />
          </Grid>
          <Grid item sm={11} sx={{ alignContent: "center" }}>
            <Typography>minutes to count as half day</Typography>
          </Grid>
        </>
      )}

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Grid>
  );
}
