import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import { CircularProgress, Divider, Drawer, Grid } from "@mui/material";
import AcademicsInfo from "./AcademicsInfo";
import Files from "./Files";
import Preferences from "./Preferences";
import Notes from "./Notes";
import { loader } from "../../sharedStyles";
import GeneralInfoProfile from "./GeneralInfoProfile";
import profileSectionsDivider from "./StudentInfoProfile.style";
import AutoSave from "../../AutoSave";
import studentsService from "../../../service/studentsService";
import Authorize from "../../Authorize";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import CustomFieldRenderer from "../../School/CustomFields/CustomFieldRenderer/CustomFieldRenderer";
import EnrollmentsVersion from "./EnrollmentsVersion";

export default function StudentInfoProfile() {
  const [, open] = useOutletContext();
  const methods = useForm({ mode: "onChange" });
  const params = useParams();
  const [student, setStudent] = useState(null);
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const [customFieldResponses, setCustomFieldResponses] = useState([]);
  const [enrollmentsVersionOpen, setEnrollmentsVersionOpen] = useState(false);

  const studentProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PROFILE
  );

  useEffect(() => {
    if (!enrollmentsVersionOpen) {
      (async () => {
        const response = await studentsService.fetchProfileDetails(
          params.student_slug,
          {
            params: { school_id: params.school_id },
          }
        );
        setStudent(response);
        setCustomFieldResponses(response.custom_field_responses);
      })();
    }
  }, [enrollmentsVersionOpen]);

  useEffect(() => {
    (async () => {
      const response = await studentsService.fetchProfileDetails(
        params.student_slug,
        {
          params: { school_id: params.school_id },
        }
      );
      setStudent(response);
      setCustomFieldResponses(response.custom_field_responses);
    })();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Authorize permission={studentProfilePermission}>
      {student ? (
        <>
          <FormProvider {...methods}>
            <Grid container item md={open ? 12 : 9.5} paddingX={1}>
              <GeneralInfoProfile
                student={student}
                setEnrollmentsVersionOpen={setEnrollmentsVersionOpen}
              />
              <Divider sx={profileSectionsDivider} />
              <AcademicsInfo open={open} student={student} />
              <Divider sx={profileSectionsDivider} />
              <Grid container spacing={2} mx={1} mt={2}>
                <Grid item container spacing={2} xs={12} md={12} pr={2}>
                  <Grid container item>
                    <Grid item xs={12} sm={12} md={12} mb={2} px={3}>
                      <CustomFieldRenderer
                        sectionType="student_profile"
                        customFieldResponses={customFieldResponses}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={profileSectionsDivider} />
              <Files student={student} />
              <Divider sx={profileSectionsDivider} />
              <Preferences student={student} />
              <Divider sx={profileSectionsDivider} />
              <Notes student={student} />
            </Grid>
            {methods.formState.isDirty && (
              <AutoSave
                saveMethod={studentsService.updateProfile}
                params={[student.slug]}
                queryParams={{
                  school_id: params.school_id,
                  school_year_id: params.school_year_id,
                }}
                successMessage="Profile saved"
                progressMessage="Saving profile..."
              />
            )}
          </FormProvider>

          <Drawer anchor="right" open={enrollmentsVersionOpen}>
            <EnrollmentsVersion
              setEnrollmentsVersionOpen={setEnrollmentsVersionOpen}
            />
          </Drawer>
        </>
      ) : (
        <CircularProgress sx={loader} size={100} />
      )}
    </Authorize>
  );
}
