import React from "react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import formattedGrade from "../../../utils/formattedGrade";
import { py5 } from "../../sharedStyles";

export default function ClassAssignments({ klass, specialMarks, termGrade }) {
  const getMarkingCodes = () => {
    const isPassFail = klass?.is_pass_fail;
    const passFailMarkingCodes = klass?.marking_codes.filter(
      (gradingScale) => gradingScale?.code === "P" || gradingScale?.code === "F"
    );

    return isPassFail ? passFailMarkingCodes : klass?.marking_codes;
  };

  const markingCodes = getMarkingCodes();

  const termLetterGrade = formattedGrade(
    termGrade?.calculated_grade,
    100,
    markingCodes,
    specialMarks
  );

  const calculatedScore = (assignment) => {
    if (
      assignment.max_score &&
      assignment.score !== "" &&
      !specialMarks.filter(
        (e) => e.code.toLowerCase() === assignment?.score?.toLowerCase()
      ).length > 0
    ) {
      return (Number(assignment.score) * 100) / assignment.max_score;
    }
    return assignment?.score;
  };

  const grade = (assignment) => {
    const score = formattedGrade(
      assignment.grading === "points" && assignment.score !== null
        ? calculatedScore(assignment)
        : assignment.score,
      assignment.max_score,
      markingCodes,
      specialMarks
    );

    return score;
  };

  const renderScore = (assignment) => {
    if (
      specialMarks.filter(
        (e) => e.code.toLowerCase() === assignment?.score?.toLowerCase()
      ).length > 0
    ) {
      const specialMark = specialMarks.find(
        (sm) => sm.code.toLowerCase() === assignment?.score?.toLowerCase()
      );

      return specialMark.score === "-"
        ? specialMark.score
        : `${specialMark.score}%`;
    }

    if (assignment.grading === "points") {
      if (assignment.score === "/") {
        return `0/${assignment.max_score}`;
      }

      if (assignment.score) {
        return `${assignment.score}/${assignment.max_score}`;
      }

      return `-/${assignment.max_score}`;
    }

    if (assignment.grading === "percent") {
      if (assignment.score === "/") {
        return "0%";
      }

      if (assignment.score) {
        return `${assignment.score}%`;
      }

      return "-";
    }

    return null;
  };

  if (klass.assignments.length > 0) {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell>{klass.name}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell align="center">
              {termGrade?.calculated_grade
                ? `${termGrade?.calculated_grade}%`
                : "-"}
            </TableCell>
            <TableCell align="left">{termLetterGrade}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {klass.assignments.map((assignment) => (
            <TableRow
              key={assignment.id}
              hover
              sx={{ borderBottom: "none !important" }}
            >
              <TableCell align="left" scope="assignment" sx={py5}>
                <Typography paddingLeft={3}>{assignment.name}</Typography>
              </TableCell>
              <TableCell align="left" sx={py5}>
                <Typography>
                  {assignment.due_date
                    ? moment(assignment.due_date).format("MM/DD/YYYY")
                    : ""}{" "}
                </Typography>
              </TableCell>
              <TableCell sx={py5}>
                {assignment.score === "/" && "Missing"}
              </TableCell>
              <TableCell align="center" sx={py5}>
                <Typography>{renderScore(assignment)}</Typography>
              </TableCell>
              <TableCell sx={py5}>{grade(assignment)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    );
  }
}
