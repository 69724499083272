export const searchStyles = {
  mb: 2,
  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
    "& fieldset": {
      borderColor: "black",
      borderLeft: "none",
      borderRight: "none",
    },
    "& input": {
      marginLeft: "30%",
    },
  },
};

export const headerButton = {
  textTransform: "capitalize",
};

export const requirementNameTypography = {
  fontSize: "1.8em",
};

export const creditsTypography = {
  fontSize: ".8em",
};

export const droppableBox = {
  border: "1px solid #ededed",
  mt: 3,
  p: 2,
};

export const tableContainerStyles = {
  "& td": { border: "none" },
};

export const noCoursesTypography = {
  textAlign: "center",
  border: "3px dashed #ededed",
  p: 4,
  borderRadius: "20px",
  color: "#999",
};

export const searchInstructionTypography = {
  mb: 1,
  mt: 2,
  fontSize: "0.9em",
  textAlign: "right",
};

export const courseListBox = {
  maxWidth: "100%",
  m: "auto",
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

export const draggableCourseGrid = {
  fontSize: "0.875rem",
  userSelect: "none",
  lineHeight: "24px",
  borderRadius: "5px",
  background: "#f9f9f9",
  padding: "10px 0 10px 10px",
  mb: 1,

  "&:hover": {
    background: "#f3f3f3",
  },
};

export const dragIndicatorStyles = {
  color: "#757575",
  display: "block",
  float: "right",
};

export const noResultsTypography = {
  textAlign: "center",
  color: "#999",
  mt: 2,
};

export const loadingBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  py: 4,
};
