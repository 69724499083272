import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Stack,
  Typography,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { font14, bgWhite } from "./sharedStyles";
import termService from "../service/termService";
import moment from "../utils/constants/momentConfig";
import dateTimeFormats from "../utils/constants/dateTimeFormats";
import { selectDropdown, selectDropdownOpen } from "./SelectDropdown.styles";
import termSelectContainer from "./TermSelect.styles";

export default function TermSelect({
  schoolId,
  loading,
  setLoading,
  selectedMonth,
  fetchUpcomingTerms = false,
  findCurrentTerm = false,
  defaultNull = false,
  showDefault = false,
  selectBox = false,
  isAttendance = false,
  show = true,
}) {
  const [selectOpen, setSelectOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const termIdParam = searchParams.get("term");
  const klassesIdsParam = searchParams.get("classes");
  const location = useLocation();

  const [terms, setTerms] = useState([]);

  const getCurrentTerm = (_terms) => {
    const currentDate = moment().utc().format(dateTimeFormats.YYYYMMDD);
    const currentSchoolYearId = _terms
      .map((_term) => _term.school_year)
      .find(
        (sy) =>
          currentDate >=
            moment(sy.start_date).utc().format(dateTimeFormats.YYYYMMDD) &&
          currentDate <=
            moment(sy.end_date).utc().format(dateTimeFormats.YYYYMMDD)
      ).id;
    const currentTerm = _terms
      .filter((t) => t.school_year.id === currentSchoolYearId)
      .find((_term) => {
        const startDate = moment(_term.start_date)
          .utc()
          .format(dateTimeFormats.YYYYMMDD);
        const endDate = moment(_term.end_date)
          .utc()
          .format(dateTimeFormats.YYYYMMDD);

        return currentDate >= startDate && currentDate <= endDate;
      });

    if (showDefault) {
      return _terms.find((term) => term.is_default);
    }

    return currentTerm || _terms.find((term) => term.is_default) || _terms[0];
  };

  const searchCurrentOrSelectedTerm = (_terms) => {
    const term =
      searchParams.get("term") && searchParams.get("term") !== "select"
        ? _terms.find(
            (_term) => _term.id && _term.id === Number(searchParams.get("term"))
          )
        : getCurrentTerm(_terms);

    searchParams.set("term", term?.id || "select");
    setSearchParams(searchParams);
  };

  const getTerms = async () => {
    const response = await termService.fetchAllTermsInSchool({
      params: {
        school_id: schoolId,
        upcoming_terms: fetchUpcomingTerms,
      },
    });
    if (response.data) {
      setTerms(response.data.terms);

      if (findCurrentTerm) {
        searchCurrentOrSelectedTerm(response.data.terms);
      } else if (defaultNull) {
        searchParams.set("term", null);
        setSearchParams(searchParams);
      } else if (!termIdParam) {
        const currentTerm = response.data.terms[response.data.terms.length - 1];
        searchParams.set("term", currentTerm.id);
        setSearchParams(searchParams);
      }
    }
  };

  const handleChange = (termId) => {
    if (setLoading) {
      setLoading(true);
    }

    searchParams.set("term", termId);
    if (klassesIdsParam) {
      searchParams.delete("classes");
    }
    setSearchParams(searchParams);
  };

  const selectedTerm = terms.find((term) => term.id === Number(termIdParam));

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    setSelectOpen(false);
  }, [location]);

  if (!show) return <span />;

  if (selectBox) {
    return (
      <Box sx={{ minWidth: "200px" }}>
        <FormControl fullWidth>
          <InputLabel id="class" shrink sx={bgWhite}>
            Select Term
          </InputLabel>
          {terms.length > 0 && (
            <Select
              labelId="class"
              id="class"
              value={termIdParam}
              disabled={
                (isAttendance && loading) ||
                (selectedMonth && selectedMonth !== "all")
              }
            >
              {defaultNull && (
                <MenuItem
                  key={0}
                  value={null}
                  onClick={() => handleChange(null)}
                >
                  Select Term
                </MenuItem>
              )}
              {terms.map((term) => (
                <MenuItem
                  key={term.id}
                  value={term.id}
                  onClick={() => handleChange(term.id)}
                >
                  {term.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
    );
  }

  return (
    terms.length > 0 && (
      <Box sx={termSelectContainer}>
        <Box
          sx={{
            ...(selectOpen ? selectDropdownOpen : selectDropdown),
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => setSelectOpen(!selectOpen)}
          >
            <Typography sx={font14} color="primary">
              {selectedTerm?.name || "Select Term"}
            </Typography>
            {selectOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Stack>
          <Collapse in={selectOpen} timeout={150}>
            {terms
              .filter((term) => term.id !== Number(termIdParam))
              .map((term) => (
                <Typography
                  key={term.id}
                  sx={{ ...font14, py: "5px" }}
                  color="primary"
                  onClick={() => handleChange(term.id)}
                >
                  {term.name}
                </Typography>
              ))}
          </Collapse>
        </Box>
      </Box>
    )
  );
}
