import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Stack,
  Box,
  Typography,
  Container,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import KlassStudentList from "./KlassStudentList";
import subjectService from "../../../../service/subjectService";
import KlassStudentAssignmentList from "./KlassStudentAssignmentList";
import {
  klassStudentDetailName,
  klassStudentDetailColumn,
  klassStudentDetailInfoCol,
  klassStudentDetailKlassAbbr,
} from "./KlassStudentDetail.styles";
import specialMarkService from "../../../../service/specialMarkService";
import { loader, mt25, mr30 } from "../../../sharedStyles";
import KlassStudentCategoryList from "./KlassStudentCategoryList";
import termGradeService from "../../../../service/termGradeService";
import KlassStudentDetailTermGrade from "./KlassStudentDetailTermGrade";
import AutoSave from "../../../AutoSave";
import klassesStudentsService from "../../../../service/klassesStudentsService";
import formatFullName from "../../../../utils/formatFullName";

export default function KlassStudentDetail() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const schoolId = params.school_id;
  const subjectId = params.subject_id;
  const studentId = params.student_id;
  const klassId = params.klass_id;

  const [activeStudent, setActiveStudent] = useState(null);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [categoryScores, setCategoryScores] = useState([]);

  const methods = useForm({ mode: "onChange" });

  const getStudentDetail = async () => {
    const response = await subjectService.fetchStudentDetail(
      subjectId,
      klassId,
      studentId,
      {
        params: {
          term_id: searchParams.get("term"),
        },
      }
    );
    if (response) {
      setActiveStudent(response);
      methods.reset({}, { keepValues: true });
    }
  };

  const getSpecialMarks = async () => {
    const response = await specialMarkService.fetchSchoolSpecialMarks({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSpecialMarks(response.data.special_marks);
    }
  };

  const getScores = async () => {
    const response = await termGradeService.fetchCategoryScores(studentId, {
      params: {
        klass_id: klassId,
        term_id: searchParams.get("term"),
      },
    });

    if (response.assignment_student) {
      setCategoryScores(response.category_scores);
    }
  };

  const hasPointsGrading = activeStudent?.assignments.some(
    (assignment) => assignment.grading === "points"
  );

  useEffect(() => {
    methods.trigger();
    methods.setValue("comment", activeStudent?.klass_student?.comment);
  }, [activeStudent]);

  useEffect(() => {
    getStudentDetail();
    getSpecialMarks();
    getScores();
  }, [subjectId, klassId, studentId, searchParams.get("term")]);

  if (activeStudent) {
    return (
      <Container maxWidth={false} variant="header">
        <FormProvider {...methods}>
          <Container maxWidth="xl">
            {activeStudent?.klass_student ? (
              <Stack direction="row">
                <Box sx={{ position: "relative" }}>
                  <KlassStudentList
                    subjectId={subjectId}
                    schoolId={schoolId}
                    studentId={studentId}
                    klassId={klassId}
                  />
                </Box>
                {activeStudent?.klass_student?.is_deleted ? (
                  <Box px={4}>
                    <Typography>
                      This student is not enrolled in this class.
                    </Typography>
                  </Box>
                ) : (
                  <Stack direction="row" sx={klassStudentDetailInfoCol}>
                    <Box sx={mr30}>
                      <Typography sx={klassStudentDetailName}>
                        {formatFullName(activeStudent?.klass_student)}
                      </Typography>
                      <Box sx={klassStudentDetailColumn}>
                        {activeStudent?.assignments.length > 0 ? (
                          <KlassStudentAssignmentList
                            assignments={activeStudent?.assignments}
                            klass={activeStudent?.klass_student?.klass}
                            activeStudent={activeStudent}
                            specialMarks={specialMarks}
                            getStudentDetail={getStudentDetail}
                            hasPointsGrading={hasPointsGrading}
                          />
                        ) : (
                          <Typography>
                            No assignments for this student.
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography sx={klassStudentDetailKlassAbbr}>
                            {activeStudent?.klass_student?.klass.abbreviation}
                          </Typography>
                        </Box>
                        <Box>
                          <KlassStudentDetailTermGrade
                            termGrade={activeStudent?.term_grade}
                            specialMarks={specialMarks}
                            markingCodes={
                              activeStudent?.klass_student?.klass?.grading_scale
                                ?.marking_codes
                            }
                          />
                        </Box>
                      </Stack>
                      <KlassStudentCategoryList categories={categoryScores} />
                      <Box sx={mt25}>
                        <TextField
                          id="filled-multiline-flexible"
                          label="Progress Report Comment"
                          multiline
                          maxRows={4}
                          fullWidth
                          defaultValues={activeStudent?.klass_student?.comment}
                          {...methods.register("comment")}
                        />
                      </Box>
                    </Box>
                  </Stack>
                )}
              </Stack>
            ) : (
              <Typography>Student not found.</Typography>
            )}
          </Container>
          {methods.formState.isDirty && (
            <AutoSave
              saveMethod={klassesStudentsService.update}
              params={[activeStudent?.klass_student?.id]}
              successMessage="Comment saved"
              progressMessage="Saving comment..."
            />
          )}
        </FormProvider>
      </Container>
    );
  }
  return <CircularProgress sx={loader} size={100} />;
}
