import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Button, Box, Grid, CircularProgress } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { fetchGraduationRequirement } from "../../../../service/graduationRequirementService";
import courseService from "../../../../service/courseService";
import { createGraduationMapping } from "../../../../service/graduationMappingService";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";
import { loader } from "../../../sharedStyles";
import graduationPlansContainer from "../GraduationPlans.style";
import RequirementDetails from "./RequirementDetails";
import CourseSearch from "./CourseSearch";

export default function PlanRequirement() {
  const { hasPermission } = useContext(PermissionsContext);
  const navigate = useNavigate();
  const { school_id: schoolId, planId, id } = useParams();
  const [requirement, setRequirement] = useState();
  const [loading, setLoading] = useState(true);
  const [creditsCourses, setCreditsCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingMappings, setLoadingMappings] = useState({});
  const [errorMappings, setErrorMappings] = useState({});
  const [warningMappings, setWarningMappings] = useState({});
  const [successMappings, setSuccessMappings] = useState({});
  const [courseError, setCourseError] = useState(null);

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.GRADUATION_PLANS,
    PERMISSION_TYPES.MANAGE
  );

  const fetchRequirement = async () => {
    if (!loading) setLoading(true);

    try {
      const response = await fetchGraduationRequirement(planId, id);
      setRequirement(response.data);
      const mappings = response.data.graduation_credits.flatMap((credit) =>
        credit.graduation_mappings.map((mapping) => ({
          ...mapping,
          creditId: credit.id,
        }))
      );
      setCreditsCourses(mappings);
    } catch (error) {
      console.error("Error fetching graduation requirement:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCourses = () => {
    setLoadingCourses(true);
    setCourseError(null);

    courseService
      .fetchAllCourses()
      .then((response) => {
        setCourses(response.data);
        setLoadingCourses(false);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        setCourseError("Failed to load courses. Please try again.");
        setLoadingCourses(false);
      });
  };

  const goBack = () => {
    navigate(`/school/${schoolId}/school/graduation-plans/${planId}`);
  };

  const showWarningForCredit = (creditId) => {
    setWarningMappings((prev) => ({ ...prev, [creditId]: true }));
    setTimeout(() => {
      setWarningMappings((prev) => ({ ...prev, [creditId]: false }));
    }, 5000);
  };

  const showSuccessMessage = (creditId) => {
    setSuccessMappings((prev) => ({ ...prev, [creditId]: true }));
    setTimeout(() => {
      setSuccessMappings((prev) => ({ ...prev, [creditId]: false }));
    }, 3000);
  };

  const onDragEnd = async (result) => {
    setErrorMappings({});
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId === "availableCourses") return;

    const creditId = Number(destination.droppableId);
    const courseId = Number(draggableId);

    const alreadyMapped = creditsCourses.find(
      (course) => course.course_id === courseId && course.creditId === creditId
    );

    if (alreadyMapped) {
      showWarningForCredit(creditId);
      return;
    }

    const course = courses.find((c) => c.id === courseId);

    setLoadingMappings((prev) => ({ ...prev, [creditId]: true }));
    setErrorMappings((prev) => ({ ...prev, [creditId]: false }));

    try {
      const response = await createGraduationMapping(creditId, {
        course_id: course.id,
      });

      setCreditsCourses((prev) => [
        ...prev,
        {
          id: response.data.id,
          course_id: course.id,
          creditId,
          course_name: course.name,
          description: course.description,
          credits_pluralized: response.data.credits_pluralized,
        },
      ]);

      showSuccessMessage(creditId);
    } catch (error) {
      console.error("Error creating graduation mapping:", error);
      setErrorMappings((prev) => ({ ...prev, [creditId]: true }));
    } finally {
      setLoadingMappings((prev) => ({ ...prev, [creditId]: false }));
    }
  };

  useEffect(() => {
    if (id) {
      fetchRequirement();
      fetchCourses();
    }
  }, [id]);

  if (loading) return <CircularProgress sx={loader} size={100} />;

  if (!requirement) return <span>Error loading requirement.</span>;

  return (
    <Box sx={graduationPlansContainer}>
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h2" sx={{ mt: 3, mb: 1 }}>
            {requirement.graduation_plan.name}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            color="info"
            size="small"
            onClick={goBack}
            sx={{ textTransform: "capitalize" }}
          >
            Return to Graduation Plan
          </Button>
        </Grid>
      </Grid>

      <Box className="plan-container">
        <Typography variant="h5">
          {requirement.graduation_plan.description}
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={10} sx={{ mt: 1 }}>
            <Grid item xs={7}>
              <Grid
                container
                alignItems="center"
                sx={{ pb: 1, borderBottom: "1px solid black" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h5" sx={{ fontSize: "1.8em" }}>
                    {requirement.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography sx={{ fontSize: ".8em" }}>
                    {requirement.required_credits} credits (
                    {requirement.short_details})
                  </Typography>
                </Grid>
              </Grid>

              <RequirementDetails
                requirement={requirement}
                creditsCourses={creditsCourses}
                setCreditsCourses={setCreditsCourses}
                loadingMappings={loadingMappings}
                errorMappings={errorMappings}
                setErrorMappings={setErrorMappings}
                warningMappings={warningMappings}
                setWarningMappings={setWarningMappings}
                successMappings={successMappings}
                setSuccessMappings={setSuccessMappings}
                hasManagePermission={hasManagePermission}
              />
            </Grid>

            <Grid item xs={5}>
              {!loadingCourses && courses.length > 0 && (
                <CourseSearch
                  courses={courses}
                  loadingCourses={loadingCourses}
                  creditsCourses={creditsCourses}
                  hasManagePermission={hasManagePermission}
                />
              )}
              {loadingCourses && <CircularProgress />}
              {courseError && (
                <Typography color="error">{courseError}</Typography>
              )}
            </Grid>
          </Grid>
        </DragDropContext>
      </Box>
    </Box>
  );
}
