import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import ProgressReportHeader from "./ProgressReportHeader";
import reportsService from "../../../service/reportsService";
import ProgressReportBody from "./ProgressReportBody";

export default function ProgressReport() {
  const [report, setReport] = useState();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const teacherId = searchParams.get("teacherId");
  const termId = searchParams.get("term");
  const [
    schoolName,
    ,
    selectedTeacherStudent,
    ,
    ,
    ,
    ,
    ,
    ,
    schoolOrgCode,
    ,
    ,
    selectedGradeLevel,
  ] = useOutletContext();

  useEffect(() => {
    setReport(null);

    setLoading(true);
    if (!teacherId && !selectedGradeLevel && selectedTeacherStudent === "all") {
      setLoading(false);
      return;
    }
    reportsService
      .studentProgressReport({
        school_id: params.school_id,
        teacher_id: teacherId !== "all" ? teacherId : undefined,
        term_id: termId,
        student_id:
          selectedTeacherStudent !== "all" ? selectedTeacherStudent : null,
        grade_level: selectedGradeLevel,
      })
      .then((response) => {
        setReport(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [teacherId, selectedTeacherStudent, termId, selectedGradeLevel]);

  if (loading)
    return (
      <Box display="flex" justifyContent="center">
        <Typography color="black" fontSize="20px" mt={5}>
          Loading...
        </Typography>
      </Box>
    );

  if (!report)
    return (
      <Box sx={{ textAlign: "center", mt: "100px" }}>
        <Typography>Please select teacher and student</Typography>
      </Box>
    );

  return (
    <PrintLayout
      headerContent={
        <ProgressReportHeader
          headerData={{
            yearName: report.school_year.start_end_year,
            termName: report.term.name,
          }}
          schoolName={schoolName}
          schoolOrgCode={schoolOrgCode}
        />
      }
      bodyContent={
        <tr className="report-data">
          <td
            className="report-content-cell"
            style={{ padding: "2rem 1rem 0 2rem" }}
          >
            <div>
              <ProgressReportBody report={report} />
            </div>
          </td>
        </tr>
      }
      footerContent={schoolName}
    />
  );
}
