const attendanceSummaryTable = {
  maxWidth: "850px",
  mx: "2rem",
  mt: "30px",

  "& th": {
    color: "black",
    fontWeight: "bold",
    borderBottom: "none",
    p: 0,
  },
};

const attendanceSummaryBorder = {
  borderRight: "1px solid black",
};

const attendanceSummaryTableCell = {
  borderBottom: "none",
  p: 0,
};

const attendanceSummaryTableCellBorder = {
  ...attendanceSummaryBorder,
  ...attendanceSummaryTableCell,
};

const attendanceSummaryCode = {
  textAlign: "center",
  width: "40px",
  fontSize: "9px",
  fontWeight: "bold",
};

const attendanceSummaryCodeB = {
  ...attendanceSummaryCode,
  ...attendanceSummaryBorder,
};

const attendanceSummaryCount = {
  textAlign: "center",
  width: "40px",
  color: "black",
};

const attendanceSummaryCountB = {
  ...attendanceSummaryCount,
  ...attendanceSummaryBorder,
};

const attendanceSummaryParagraph = {
  fontSize: "14px",
  fontWeight: "normal",
  color: "black",
};

const attendanceSummaryName = {
  ...attendanceSummaryParagraph,
  fontWeight: "bold",
};

const attendanceSummaryTableHeaderEx = {
  textAlign: "center",
  lineHeight: 3,
  fontSize: "9px",
  fontWeight: "bold",
  width: "80px",
};

const attendanceSummaryTableHeaderExB = {
  ...attendanceSummaryTableHeaderEx,
  ...attendanceSummaryBorder,
};

const attendanceSummaryTableHeaderLabel = {
  ...attendanceSummaryBorder,
  fontSize: "9px",
  fontWeight: "bold",
  width: "160px",
};

const attendanceSummaryTableHeaderLabelP = {
  ...attendanceSummaryTableHeaderLabel,
  width: "80px",
};

export {
  attendanceSummaryTable,
  attendanceSummaryTableCellBorder,
  attendanceSummaryBorder,
  attendanceSummaryTableCell,
  attendanceSummaryCode,
  attendanceSummaryCodeB,
  attendanceSummaryParagraph,
  attendanceSummaryCount,
  attendanceSummaryCountB,
  attendanceSummaryTableHeaderEx,
  attendanceSummaryTableHeaderExB,
  attendanceSummaryTableHeaderLabel,
  attendanceSummaryTableHeaderLabelP,
  attendanceSummaryName,
};
