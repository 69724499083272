import React, { useEffect, useState, useContext, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  ButtonBase,
} from "@mui/material";
import termGradeService from "../../../service/termGradeService";
import skillsService from "../../../service/skillsService";
import studentSkillsService from "../../../service/studentSkillsService";
import { font12, loader } from "../../sharedStyles";
import TermGradeSkillRow from "./TermGradeSkillRow";
import {
  termGradeLevelsHead,
  termGradeSkillHead,
  termGradeSkillsHeadTerm,
  termGradeLevelsBorderedHead,
  termGradeTermSkillsHead,
  termGradeTermSkillsHeadBox,
  emptyMessageContainer,
  tableContainer,
  tableHead,
} from "./TermGradeSkills.styles";
import { QuickBarContext } from "../../../context/QuickBarContext";
import reportCardsService from "../../../service/reportCardsService";
import PopoverComponent from "../../ToolTip/ToolTip";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { stickyCell } from "./TermGradeSkillRow.styles";

export default function TermGradeSkills() {
  const [schoolId, subjectId, klassesIds, termId] = useOutletContext();
  const [termGrades, setTermGrades] = useState([]);
  const quickbarContext = useContext(QuickBarContext);
  const [termGradesByKlassID, setTermGradesByKlassID] = useState({});
  const [termGradesByStudentID, setTermGradesByStudentID] = useState({});
  const [skillsByGradingScaleID, setSkillsByGradingScaleID] = useState({});
  const [skillsByID, setSkillsByID] = useState({});
  const [studentSkillsByKlassAndStudent, setStudentSkillsByKlassAndStudent] =
    useState({});
  const [skills, setSkills] = useState([]);
  const [gradingScales, setGradingScales] = useState([]);
  const [reportCards, setReportCards] = useState([]);
  const [studentSkills, setStudentSkills] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [currentGradingScale, setCurrentGradingScale] = useState(null);
  const snackbarContext = useContext(SnackbarContext);
  const timeout = useRef();
  const [currentTerm, setCurrentTerm] = useState([]);

  const setTermGradesMaps = () => {
    const tgByKlass = {};
    const tgByStudent = {};

    termGrades.forEach((termGrade) => {
      tgByKlass[termGrade.klass.id] = termGrade;
      tgByStudent[termGrade.student.id] = termGrade;
    });

    setTermGradesByKlassID(tgByKlass);
    setTermGradesByStudentID(tgByStudent);
  };

  const setSkillsMaps = () => {
    const tempSkillsByGradingScaleID = {};

    const parsedSkills = skills.map((gs) => gs.skills).flat();

    parsedSkills.forEach((skill) => {
      if (tempSkillsByGradingScaleID[skill.grading_scale_id] === undefined) {
        tempSkillsByGradingScaleID[skill.grading_scale_id] = [skill];
      } else {
        tempSkillsByGradingScaleID[skill.grading_scale_id].push(skill);
      }
    });

    const skillsBySkillID = {};

    parsedSkills.forEach((skill) => {
      if (skillsBySkillID[skill.id] === undefined) {
        skillsBySkillID[skill.id] = [skill];
      } else {
        skillsBySkillID[skill.id].push(skill);
      }
    });

    setSkillsByGradingScaleID(tempSkillsByGradingScaleID);
    setSkillsByID(skillsBySkillID);
  };

  const setStudentSkillsMap = () => {
    const studentSkillsMap = {};

    studentSkills.forEach((studentSkill) => {
      const isInFilters =
        studentSkill.student_id in termGradesByStudentID &&
        studentSkill.klass_id in termGradesByKlassID &&
        studentSkill.skill_id in skillsByID;

      if (isInFilters) {
        const gradingScaleID =
          termGradesByKlassID[studentSkill.klass_id].klass.grading_scale_id;
        if (
          studentSkillsMap[
            `${studentSkill.student_id}-${studentSkill.klass_id}`
          ] === undefined
        ) {
          studentSkillsMap[
            `${studentSkill.student_id}-${studentSkill.klass_id}`
          ] = {
            grading_scale_id: gradingScaleID,
            student_skills: [studentSkill],
          };
        } else {
          studentSkillsMap[
            `${studentSkill.student_id}-${studentSkill.klass_id}`
          ].student_skills.push(studentSkill);
        }
      }
    });

    setStudentSkillsByKlassAndStudent(studentSkillsMap);
  };

  const getTermGrades = async () => {
    setLoading(true);
    const response = await termGradeService.fetchTermGrades({
      params: {
        klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
        subject_id: subjectId,
        school_id: schoolId,
        term_id: termId,
      },
    });

    if (response.term_grades) {
      setTermGrades(response.term_grades);
      setCurrentTerm(response.term_grades[0]?.term);
      setLoading(false);
    }
  };

  const getSkills = async () => {
    const response = await skillsService.fetchAllSkills({
      params: {
        school_id: schoolId,
        term_id: termId,
        subject_id: subjectId,
        klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
      },
    });

    if (response.data) {
      setGradingScales(response.data);
      setSkills(response.data);
    }
  };

  const getStudentSkills = async () => {
    const response = await studentSkillsService.fetchAllStudentSkills({
      params: {
        subject_id: subjectId,
      },
    });

    if (response.data) {
      setStudentSkills(response.data);
    }
  };

  const getReportCards = async () => {
    const response = await reportCardsService.fetchReportCards({
      term_id: termId,
    });

    if (response.data) {
      setReportCards(response.data);
    }
  };

  const subjectHasSkills = () =>
    [].concat(...gradingScales.map((gs) => gs.skills)).length > 0;

  useEffect(() => {
    (async () => {
      await getTermGrades();
      await getSkills();
      await getStudentSkills();
    })();
  }, [klassesIds, subjectId, termId]);

  useEffect(() => {
    setTermGradesMaps(termGrades);
  }, [termGrades]);

  useEffect(() => {
    (async () => {
      await getStudentSkills();
    })();
  }, [subjectId]);

  useEffect(() => {
    setSkillsMaps(skills);
  }, [skills]);

  useEffect(() => {
    if (!studentSkills) {
      return;
    }
    setStudentSkillsMap(studentSkills);
  }, [studentSkills]);

  useEffect(() => {
    (async () => {
      await getReportCards();
    })();
  }, [termId]);

  useEffect(() => {
    (async () => {
      if (quickbarContext.postSkills === true) {
        const studentSkillsToPost = [];

        // eslint-disable-next-line no-unused-vars,no-restricted-syntax
        for (const [_key, value] of Object.entries(
          studentSkillsByKlassAndStudent
        )) {
          const studentSkillGradingScale =
            skillsByGradingScaleID[value.grading_scale_id];

          if (
            value.student_skills.filter((ss) => ss.score !== "").length ===
            studentSkillGradingScale.length
          ) {
            value.student_skills.forEach((ss) => {
              const updatedAt = new Date(ss.updated_at).getTime();
              const postedAt = new Date(ss.posted_at).getTime();

              if (postedAt === null || updatedAt > postedAt) {
                studentSkillsToPost.push(ss.id);
              }
            });
          }
        }

        await studentSkillsService.postGrades({
          ids: studentSkillsToPost,
        });
        quickbarContext.cleanFirePostSkills();

        await getStudentSkills();
      }
    })();
  }, [quickbarContext.postSkills]);

  if (gradingScales.length === 0 || !studentSkills || loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (!subjectHasSkills()) {
    return (
      <Box sx={emptyMessageContainer}>
        <Typography>No skills for this subjects term</Typography>
      </Box>
    );
  }

  if (termGrades.length === 0) {
    return (
      <Box sx={emptyMessageContainer}>
        <Typography>No students for this class</Typography>
      </Box>
    );
  }

  const updateSkillGrade = (studentSkillsArray) => {
    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving score...",
      severity: "info",
      open: true,
    });
    timeout.current = setTimeout(async () => {
      const postSkill = await studentSkillsService
        .createMultipleStudentSkills({
          student_skills: studentSkillsArray,
        })
        .catch(() =>
          snackbarContext.setSnackbar({
            message: "Error while saving scores.",
            severity: "error",
            open: true,
          })
        );

      if (postSkill) {
        snackbarContext.setSnackbar({
          message: "Grades saved successfully",
          severity: "success",
          open: true,
        });

        await getStudentSkills();
      }
    }, 2000);
  };

  const handleGradeChange = (anchor, targetClass, gs) => {
    setAnchorEl(anchor);
    setCurrentTarget(targetClass);
    setCurrentGradingScale(gs);
  };

  const handleTooltipInput = (input) => {
    const inputs = document.getElementsByClassName(currentTarget);
    const studentSkillsArray = [];
    for (let i = 0; i < inputs.length; i += 1) {
      if (!inputs[i].value) {
        inputs[i].value = input;
        const splitIds = inputs[i].id.split("_");
        const termGradeId = Number(splitIds[0]);
        const skillId = splitIds[1];
        const termGrade = termGrades.find((tg) => tg.id === termGradeId);
        studentSkillsArray.push({
          klass_id: termGrade.klass_id,
          subject_id: termGrade.klass.subject_id,
          student_id: termGrade.student_id,
          skill_id: skillId,
          score: input,
        });
      }
    }
    updateSkillGrade(studentSkillsArray);
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer sx={tableContainer}>
        <Table stickyHeader>
          <TableHead sx={tableHead}>
            <TableRow>
              <Box sx={stickyCell}>
                <TableCell colSpan={2} sx={termGradeTermSkillsHead}>
                  <Box sx={termGradeTermSkillsHeadBox}>
                    {currentTerm?.name} skill grades
                  </Box>
                </TableCell>
                <TableCell sx={termGradeLevelsHead} />
                <TableCell sx={termGradeLevelsHead} />
              </Box>
              {gradingScales.map(
                (gs) =>
                  gs.skills.length > 0 && (
                    <TableCell
                      colSpan={gs.skills.length}
                      sx={termGradeLevelsBorderedHead}
                      key={gs.id}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <ButtonBase
                          onClick={(e) => {
                            handleGradeChange(
                              e.currentTarget,
                              `grading_input_${gs.id}`,
                              gs
                            );
                          }}
                        >
                          <Typography>{gs.name}</Typography>
                        </ButtonBase>
                      </Box>
                    </TableCell>
                  )
              )}
            </TableRow>
            <TableRow>
              <Box sx={{ ...stickyCell, verticalAlign: "bottom !important" }}>
                <TableCell sx={{ ...termGradeSkillHead, width: "100vw" }} />
                <TableCell align="center" sx={termGradeSkillHead}>
                  <Typography>Class</Typography>
                </TableCell>
                <TableCell sx={termGradeSkillHead} align="center">
                  <Typography>
                    Student <br /> Level
                  </Typography>
                </TableCell>
              </Box>
              {gradingScales.map((gs) =>
                gs?.skills.map((skill) => (
                  <TableCell sx={termGradeSkillsHeadTerm} key={skill.id}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ButtonBase
                        onClick={(e) => {
                          handleGradeChange(
                            e.currentTarget,
                            `skill_input_${skill.id}`,
                            gs
                          );
                        }}
                      >
                        <Typography sx={font12}>{skill.name}</Typography>
                      </ButtonBase>
                    </Box>
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {termGrades.map((termGrade) => (
              <TermGradeSkillRow
                key={termGrade.id}
                termGrade={termGrade}
                studentSkills={studentSkills}
                skillsByGradingScaleID={skillsByGradingScaleID}
                studentSkillsByKlassAndStudent={studentSkillsByKlassAndStudent}
                gradingScales={gradingScales}
                getStudentSkills={getStudentSkills}
                reportCards={reportCards}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={handleTooltipInput}
        currentTarget={currentTarget}
        showSpecialMarks={false}
        specialMarks={null}
        markingCodes={currentGradingScale?.marking_codes}
        skill={currentGradingScale}
        render
        isLetterGrade
      />
    </>
  );
}
