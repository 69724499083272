import React from "react";
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { font14, mt35, paginationContent } from "./sharedStyles";

export default function ListPagination({
  totalPages,
  handleChangePage,
  page,
  count,
  setCount,
  total,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRowPerPageChange = (e) => {
    searchParams.set("count", e.target.value);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    setCount(e.target.value);
  };

  const startItem = (page - 1) * count + 1;
  const endItem = Math.min(page * count, total);

  return (
    <Stack
      sx={mt35}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={font14}>Rows per page:</Typography>
        <FormControl sx={{ ml: "10px", width: "70px" }} variant="standard">
          <Select
            id="rowsPerPage"
            label="Rows per page"
            value={count}
            onChange={(e) => handleRowPerPageChange(e)}
          >
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="150">150</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value={total}>All</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Pagination
        count={totalPages}
        onChange={handleChangePage}
        sx={paginationContent}
        page={parseInt(page, 10)}
        showFirstButton
        showLastButton
      />

      <Typography>
        Showing {startItem}-{endItem} of {total}
      </Typography>
    </Stack>
  );
}
