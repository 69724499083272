import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function GradeLevelSelect({
  gradeLevels,
  selectedGradeLevel,
  setSelectedGradeLevel,
}) {
  return (
    <Grid item md={8} pt={6} pl={4}>
      {gradeLevels?.length > 0 && (
        <FormControl fullWidth>
          <InputLabel id="grade_level_label">Grade Level</InputLabel>
          <Select
            labelId="grade_level_label"
            id="gradeLevel"
            label="Grade Level"
            value={selectedGradeLevel}
            onChange={(e) => setSelectedGradeLevel(e.target.value)}
          >
            <MenuItem value="all">Select</MenuItem>
            {gradeLevels?.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
