import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import AttendanceSummaryMonthSelect from "./AttendanceSummaryMonthSelect";
import TermSelect from "../../TermSelect";

export default function AttendanceSummaryViewSelect({
  schoolDaysByMonth,
  handleMonthSelect,
  selectedMonth,
  loading,
  searchParams,
  setSearchParams,
  setLoading,
  schoolId,
}) {
  const [viewMode, setViewMode] = useState("year");

  const handleViewSelect = (value) => {
    if (value === "year") {
      handleMonthSelect("all");
      searchParams.set("term", null);
      setSearchParams(searchParams);
    }

    if (value === "month") {
      searchParams.set("term", null);
      setSearchParams(searchParams);
    }

    if (value === "term") {
      handleMonthSelect("all");
    }

    setViewMode(value);
  };

  const handleViewChildren = () => {
    if (viewMode === "month") {
      return (
        <Box mt={3}>
          <AttendanceSummaryMonthSelect
            schoolDaysByMonth={schoolDaysByMonth}
            handleMonthSelect={handleMonthSelect}
            selectedMonth={selectedMonth}
            loading={loading}
            searchParams={searchParams}
          />
        </Box>
      );
    }

    if (viewMode === "term") {
      return (
        <Box mt={3}>
          <TermSelect
            schoolId={schoolId}
            loading={loading}
            setLoading={setLoading}
            selectedMonth={selectedMonth}
            defaultNull
            fetchUpcomingTerms
            selectBox
            isAttendance
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="view-label">Select view</InputLabel>
        <Select
          labelId="view-mode"
          id="viewMode"
          label="Select View"
          value={viewMode}
          onChange={(e) => handleViewSelect(e.target.value)}
        >
          <MenuItem value="year">View by year</MenuItem>
          <MenuItem value="month">View by month</MenuItem>
          <MenuItem value="term">View by term</MenuItem>
        </Select>
      </FormControl>
      {handleViewChildren()}
    </>
  );
}
