const graduationPlansContainer = {
  color: "black",

  "& td, & th": {
    padding: 1,
    color: "black",
  },

  "& .plan-container": {
    marginLeft: 6,
    marginRight: 6,
  },

  "& .bordered": {
    border: "1px solid #e0e0e0",
    padding: 2,
  },
};

export default graduationPlansContainer;
