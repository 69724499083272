import React, { useEffect, useRef } from "react";
import { Button, Stack } from "@mui/material";
import {
  filterByKlassBtn,
  filterByKlassBtnActive,
} from "../Subject/SubjectFilterByClass.styles";

export default function KlassSelect({
  klasses,
  setKlasses,
  manageAllStudentsPermission,
}) {
  const scrollRef = useRef();
  const handleClick = (klassId) => {
    const updatedKlasses = klasses.map((klass) => ({
      ...klass,
      is_active: klass.id === klassId,
    }));
    setKlasses(updatedKlasses);
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }

    return () => false;
  }, []);

  return (
    <Stack
      direction="row"
      sx={{
        overflowX: "hidden",
        width: "100%",
        ml: "20px",
        maxWidth: "670px",
        textAlign: "right",
        whiteSpace: "nowrap",
        display: "block",
        mt: manageAllStudentsPermission ? "15px" : 0,
      }}
      ref={scrollRef}
    >
      {klasses.length > 0 &&
        klasses.map((klass) => (
          <Button
            onClick={() => handleClick(klass.id)}
            sx={klass.is_active ? filterByKlassBtnActive : filterByKlassBtn}
            key={klass.id}
          >
            {klass.abbreviation}
          </Button>
        ))}
    </Stack>
  );
}
