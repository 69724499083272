import * as React from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MoreVert } from "@mui/icons-material";
import {
  staffTableBox,
  staffTableHeaderCell,
  staffTableCellWide,
  staffTablePaper,
  staffTableSpan,
  staffTableCell,
  staffTableHeadCell,
  staffTableHeadCellActive,
  staffTableCellTeacher,
} from "./StaffTable.style";
import { tableAvatar, textCenter, pt30, loader } from "./sharedStyles";
import temporaryStaffService from "../service/temporaryStaffService";
import { StaffActionsContext } from "../context/StaffActionsContext";
import RecordSubstitute from "./Staff/TemporaryStaff/RecordSubstitute";
import ListPagination from "./ListPagination";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
    sortable: true,
    align: "left",
  },
  {
    id: "position",
    numeric: false,
    disablePadding: true,
    label: "POSITION",
    sortable: true,
    align: "left",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "PHONE",
    sortable: false,
    align: "left",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "EMAIL",
    sortable: false,
    align: "left",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: true,
    align: "center",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isHeaderActive = (id, sortable) =>
    orderBy === id
      ? staffTableHeadCellActive(sortable)
      : staffTableHeadCell(sortable);

  const renderSortChevron = (id) => {
    if (id !== orderBy) return "";

    return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={staffTableHeadCell(false)} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ borderBottom: "none" }}
          >
            <Box
              {...(headCell.sortable && {
                onClick: createSortHandler(headCell.id),
              })}
              sx={isHeaderActive(headCell.id, headCell.sortable)}
              margin={headCell.align === "center" ? "0 auto" : ""}
            >
              {headCell.label}
              {renderSortChevron(headCell.id)}
            </Box>
          </TableCell>
        ))}
        <TableCell width="2.5%" sx={{ borderBottom: "none" }} />
      </TableRow>
    </TableHead>
  );
}

export default function TemporaryStaffTable({
  isCompact,
  staffs,
  searchParams,
  setSearchParams,
  selected,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  setSelected,
  setPage,
  totalPages,
  page,
  loading,
  count,
  setCount,
  totalTempStaff,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const contextMenuOpen = Boolean(anchorEl);
  const [currentStaff, setCurrentStaff] = React.useState();
  const staffActionsContext = React.useContext(StaffActionsContext);
  const [recordSubstitueOpen, setRecordSubstituteOpen] = React.useState(false);

  const handleClick = (event, staff) => {
    setAnchorEl(event.currentTarget);
    setCurrentStaff(staff);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = staffs.map((staff) => staff.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
    setSelected([]);
  };

  const getCurrentStaff = async () => {
    const response = await temporaryStaffService.fetchStaff(currentStaff.id);
    staffActionsContext.openTemporaryStaffDrawer(response);
  };

  const handleStaffDrawer = () => {
    handleClose();
    if (currentStaff.id) {
      getCurrentStaff();
    }
  };

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={staffTableBox}>
      {staffs.length > 0 ? (
        <Container maxWidth="lg">
          <Paper sx={staffTablePaper} elevation={0}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size="medium">
                {!isCompact && (
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={staffs.length}
                  />
                )}
                <TableBody>
                  {staffs.map((staff) => {
                    const labelId = `enhanced-table-checkbox-${staff.id}`;

                    return (
                      <TableRow hover tabIndex={-1} key={staff.id}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{
                            ...staffTableHeaderCell(isCompact),
                            cursor: "default",
                          }}
                        >
                          <Avatar sx={{ ...tableAvatar, cursor: "default" }}>
                            {staff.first_name.charAt(0).toUpperCase()}
                          </Avatar>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={staffTableCellWide(isCompact)}
                        >
                          <span style={staffTableSpan(isCompact)}>
                            {`${staff.first_name} ${staff.last_name}`}
                          </span>
                        </TableCell>
                        {!isCompact && (
                          <>
                            <TableCell
                              align="center"
                              sx={staffTableCellTeacher}
                            >
                              {staff.position || "-"}
                            </TableCell>
                            <TableCell align="center" sx={staffTableCell}>
                              {staff.cell_phone || "-"}
                            </TableCell>
                            <TableCell align="center" sx={staffTableCell}>
                              {staff.email || "-"}
                            </TableCell>
                            <TableCell
                              width="5%"
                              sx={staffTableCell}
                              style={{ textAlign: "center" }}
                            >
                              {(staff.is_active && "Active") || "Inactive"}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  contextMenuOpen ? "long-menu" : undefined
                                }
                                aria-expanded={
                                  contextMenuOpen ? "true" : undefined
                                }
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, staff)}
                              >
                                <MoreVert />
                              </IconButton>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={contextMenuOpen}
              onClose={handleClose}
            >
              <MenuItem
                autoFocus={false}
                onClick={() => handleStaffDrawer(true)}
              >
                Edit Profile
              </MenuItem>
              {currentStaff?.is_active && (
                <MenuItem
                  onClick={() => {
                    setRecordSubstituteOpen(true);
                    handleClose();
                  }}
                >
                  Record Substitution
                </MenuItem>
              )}
            </Menu>
            {!isCompact && (
              <ListPagination
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                page={page}
                count={count}
                setCount={setCount}
                total={totalTempStaff}
              />
            )}
          </Paper>
        </Container>
      ) : (
        <Typography sx={[textCenter, pt30]}>
          No temporary staffs found.
        </Typography>
      )}
      {recordSubstitueOpen && (
        <RecordSubstitute
          open={recordSubstitueOpen}
          substituteId={currentStaff?.id}
          setRecordSubstituteOpen={setRecordSubstituteOpen}
          isTemporary
        />
      )}
    </Box>
  );
}
