const APP_PERMISSIONS = {
  ADD_TEMPORARY_STAFF: "Staff > Add Temporary Staff",
  ADMISSIONS: "Admissions",
  ALL_EVENTS: "All Events",
  ALL_STUDENTS: "All Students",
  ATTENDANCE: "Day Attendance",
  DATAROLLUP_SYNC: "DataRollup Sync",
  DAY_TEMPLATE: "Day Template",
  GPA: "GPA",
  GRADING_SCALES: "Grading Scales",
  KLASS_SCHEDULES: "Class Schedule",
  KLASSES_ASSIGNMENTS: "Classes > Assignments",
  KLASSES_GRADEBOOK: "Classes > Gradebook",
  KLASSES_TERM_GRADES: "Classes > TermGrade",
  KLASSES_STUDENTS: "Classes > Students",
  MY_KLASSES: "My Classes",
  MY_SUBJECTS: "My Subjects",
  ONEROSTER: "OneRoster",
  PK_TRACKER: "PK Tracker",
  REPORT_SCREEN: "Reporting Screen",
  SOCIAL_DEVELOPMENT_WORK_ETHIC: "Social Work Development/ Work Ethic",
  STAFF_LIST: "Staff List",
  STAFF_ACCOUNT: "Staff Account",
  SCHOOL_DAYS: "School Days",
  SCHOOL_PROFILE: "School Profile",
  SECURITY: "Security",
  SETTINGS: "Settings",
  GRADUATION_PLANS: "Graduation Plans",
  STUDENT_ACCOUNT: "Student Account",
  STUDENT_EVENTS: "Student Events",
  STUDENT_PORTFOLIO: "Student Portfolio",
  STUDENT_PROFILE: "Student Profile",
  STUDENT_MEDICAL: "Student Medical",
  STUDENT_MESSAGES: "Student Messages",
  STUDENT_TRANSCRIPTS: "Student Transcripts",
  STUDENT_LIST: "Student List (School-wide)",
  TERMS: "Terms",
  COURSES: "Courses",
};

export default APP_PERMISSIONS;
