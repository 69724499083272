import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import { loader } from "../../sharedStyles";

import reportsService from "../../../service/reportsService";
import AttendanceSummaryTable from "./AttendanceSummaryTable";
import schoolDayService from "../../../service/schoolDayService";

export default function AttendanceSummary() {
  const [
    schoolName,
    selectedTeacher,
    selectedTeacherStudent,
    ,
    ,
    ,
    setSchoolDaysByMonth,
    selectedMonth,
    ,
    ,
    loading,
    setLoading,
  ] = useOutletContext();

  const [searchParams] = useSearchParams();

  const [summary, setSummary] = useState(null);

  const getSchoolDaysByMonth = async () => {
    const response = await schoolDayService.byMonth();
    if (response.data) {
      const list = [];
      Object.values(response.data.school_days).forEach((_months) => {
        let date = _months[0].date.split("-");
        date = new Date(date[0], date[1] - 1, date[2]);
        const firstDayOfSchoolDays = date.getDate() - 1;
        const firstDayOfMonth =
          Number(moment(date).startOf("month").format("d")) +
          firstDayOfSchoolDays;
        const paddedSchoolDays = _months;
        for (let i = 0; i < firstDayOfMonth; i += 1) {
          paddedSchoolDays.unshift(i);
        }
        list.push({
          monthYear: date,
          schoolDays: paddedSchoolDays,
        });
      });

      setSchoolDaysByMonth(list);
    }
  };

  const getAttendanceReport = async () => {
    setLoading(true);
    const response = await reportsService.attendanceSummary({
      month: selectedMonth === "all" ? null : selectedMonth,
      term_id:
        searchParams.get("term") !== "null" ? searchParams.get("term") : null,
    });

    if (response) {
      setLoading(false);
      setSummary(response);
    }
  };

  const generateBodyContent = (
    <AttendanceSummaryTable
      key={summary?.id}
      selectedTeacher={selectedTeacher}
      selectedTeacherStudent={selectedTeacherStudent}
      selectedMonth={selectedMonth}
      summary={summary}
    />
  );

  useEffect(() => {
    getAttendanceReport();
    getSchoolDaysByMonth();
  }, [selectedMonth, searchParams.get("term")]);

  if (loading) {
    return <CircularProgress size={100} sx={loader} />;
  }

  return (
    <PrintLayout
      headerContent="Attendance Summary"
      bodyContent={generateBodyContent}
      footerContent={schoolName}
    />
  );
}
