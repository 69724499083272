import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";

export default function AttendanceSummaryMonthSelect({
  schoolDaysByMonth,
  handleMonthSelect,
  selectedMonth,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="month-label">Select month</InputLabel>
      <Select
        labelId="month-label"
        id="month"
        label="Select month"
        value={selectedMonth}
        onChange={(e) => handleMonthSelect(e.target.value)}
      >
        <MenuItem value="all">Select Month</MenuItem>
        {schoolDaysByMonth.map((month) => (
          <MenuItem
            key={moment(month.monthYear).format(dateTimeFormats.YYYYMMDD)}
            value={moment(month.monthYear).format(dateTimeFormats.YYYYMMDD)}
          >
            {moment(month.monthYear).format("MMMM")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
