import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  DragIndicator,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import * as styles from "./PlanRequirement.styles";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export default function CourseSearch({
  courses,
  loadingCourses,
  creditsCourses,
  hasManagePermission,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const searchInputRef = useRef(null);

  const filterCourses = useCallback(
    (keyword) => {
      if (!courses) return;
      const filtered = courses.filter(
        (course) =>
          course.name.toLowerCase().includes(keyword.toLowerCase()) ||
          course.description.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredCourses(filtered);
    },
    [courses]
  );

  const debouncedFilterCourses = useCallback(debounce(filterCourses, 200), [
    filterCourses,
  ]);

  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchTerm(value);

    if (value === "") {
      setFilteredCourses(courses);
    } else {
      debouncedFilterCourses(value);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredCourses(courses);
  };

  const handleSearchIconClick = () => {
    searchInputRef.current.focus();
  };

  const isCourseAdded = (courseId) =>
    creditsCourses.some((course) => course.course_id === courseId);

  useEffect(() => {
    if (hasManagePermission) {
      setFilteredCourses(courses || []);
    } else {
      setFilteredCourses(courses?.filter((course) => isCourseAdded(course.id)));
    }
  }, [courses]);

  return (
    <Box sx={{ position: "sticky", top: 24 }}>
      {hasManagePermission && (
        <>
          <Typography sx={styles.searchInstructionTypography}>
            Drag the following courses to build your requirements
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Start typing course name"
            size="small"
            sx={styles.searchStyles}
            autoComplete="course-name"
            value={searchTerm}
            onChange={handleSearchChange}
            inputRef={searchInputRef}
            disabled={courses?.length < 1}
            InputProps={{
              endAdornment: searchTerm ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch} size="small">
                    <ClearIcon sx={{ color: "#757575" }} />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSearchIconClick}
                    disabled={courses?.length < 1}
                  >
                    <SearchIcon sx={{ color: "#757575" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}

      <Droppable droppableId="availableCourses">
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              ...styles.courseListBox,
              mt: hasManagePermission ? 0 : 3,
            }}
          >
            {filteredCourses?.length > 0
              ? filteredCourses.map((course, index) => (
                  <Draggable
                    key={course.id}
                    draggableId={String(course.id)}
                    index={index}
                    isDragDisabled={!hasManagePermission}
                  >
                    {(dragProvided, snapshot) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        sx={{
                          ...styles.draggableCourseGrid,
                          boxShadow: snapshot.isDragging ? 2 : undefined,
                          opacity: snapshot.isDropAnimating ? "0" : undefined,
                          cursor: hasManagePermission ? "grab" : "default",
                        }}
                      >
                        <Grid item xs={4}>
                          <b>{course.name}</b>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: 3,
                          }}
                        >
                          {course.description}
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                          {isCourseAdded(course.id) && (
                            <Typography
                              variant="body2"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                color: "success.main",
                              }}
                            >
                              <CheckCircleIcon
                                fontSize="small"
                                sx={{ mr: 0.5 }}
                              />
                              Mapped
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                          {hasManagePermission && (
                            <DragIndicator sx={styles.dragIndicatorStyles} />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))
              : !loadingCourses && (
                  <Typography sx={styles.noResultsTypography}>
                    {searchTerm
                      ? "No courses found for your search"
                      : "No courses available"}
                  </Typography>
                )}

            {loadingCourses && (
              <Box sx={styles.loadingBox}>
                <CircularProgress />
              </Box>
            )}

            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
}
