import axios from "../utils/axios-util";

const endpoint = (creditId) => `/api/graduation_credits/${creditId}/mappings`;

const createGraduationMapping = (creditId, p) =>
  axios.post(endpoint(creditId), p);

const updateGraduationMapping = (creditId, id, p) =>
  axios.put(`${endpoint(creditId)}/${id}`, p);

const deleteGraduationMapping = (creditId, id) =>
  axios.delete(`${endpoint(creditId)}/${id}`);

export {
  createGraduationMapping,
  updateGraduationMapping,
  deleteGraduationMapping,
};
