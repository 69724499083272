import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { Box, Stack, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  loader,
} from "../sharedStyles";
import { klassFormDivider } from "./KlassForm.styles";
import GeneralInfo from "./GeneralInfo";
import Gradebook from "./Gradebook";
import GradingScale from "./GradingScale";
import DefaultAssignmentGrading from "./DefaultAssignmentGrading";
import KlassScheduleInfo from "./KlassScheduleInfo";
import Students from "./Students";
import teachersService from "../../service/staffsService";
import subjectService from "../../service/subjectService";
import courseService from "../../service/courseService";
import classScheduleService from "../../service/classScheduleService";
import dayTemplateService from "../../service/dayTemplateService";
import klassesService from "../../service/klassesService";
import gradingScaleService from "../../service/gradingScaleService";
import studentsService from "../../service/studentsService";
import StaffType from "../../utils/constants/staffTypeEnum";
import GenericSubmitButton from "../GenericSubmitButton";
import Terms from "./Terms";
import termService from "../../service/termService";
import klassTermService from "../../service/klassTermService";

function KlassForm({
  methods,
  schoolId,
  handleKlassesDrawer,
  setSnackBarOpen,
  teacherId,
  activeKlass,
  schoolYearId,
  termId,
  currentSchool,
}) {
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classSchedule, setClassSchedule] = useState(null);
  const [dayTemplates, setDayTemplates] = useState([]);
  const [gradingScales, setGradingScales] = useState([]);
  const [selectedDayTemplate, setSelectedDayTemplate] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [students, setStudents] = useState([]);
  const [unenrolledStudents, setUnenrolledStudents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [categoryList, setCategoryList] = useState([
    {
      id: 0,
      name: "Classwork",
      weight: null,
      assignmentCount: null,
      attendance_category: false,
    },
  ]);
  const [weightError, setWeightError] = useState(false);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [removedStudents, setRemovedStudents] = useState([]);
  const [studentsToEnroll, setStudentsToEnroll] = useState([]);
  const [terms, setTerms] = useState([]);
  const [klassTerms, setKlassTerms] = useState([]);
  const { setValue } = useFormContext();
  const [level, setLevel] = useState(
    (activeKlass?.prek ? "pk" : null) ||
      activeKlass?.course?.level ||
      "elementary"
  );

  const loadTeachers = async () => {
    const response = await teachersService.fetchAllStaffs({
      params: { school_id: schoolId, staff_type: StaffType.TEACHER },
    });
    setTeachers(response);
  };

  const loadSubjects = async () => {
    const response = await subjectService.fetchAllSubjects({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSubjects(response.data);
    }
  };

  const loadCourses = async () => {
    const response = await courseService.fetchAllCourses();
    if (response.data) {
      setCourses(response.data);
    }
  };

  const loadClassSchedule = async () => {
    const response = await classScheduleService.fetchClassSchedule(
      params.school_id,
      params.class_schedule_id ||
        activeKlass.klass_schedule_details[0].klass_schedule_id
    );

    if (response.data) {
      setClassSchedule(response.data.klass_schedule);
    }
  };

  const loadDayTemplates = async () => {
    const response = await dayTemplateService.fetchAllDayTemplates({
      params: {
        school_year_id: schoolYearId,
        klass_schedule_id: params.class_schedule_id,
        klass_id: activeKlass?.id,
        term_id: termId,
      },
    });

    if (response.data) {
      setDayTemplates(response.data);
      if (!selectedDayTemplate) {
        if (activeKlass) {
          setSelectedDayTemplate(
            dayTemplates.find(
              (ks) =>
                ks.id ===
                activeKlass?.klass_schedule_details[0]?.day_template_id
            )?.id
          );
        } else {
          setSelectedDayTemplate(response.data[0]?.id);
        }
      }
    }
  };

  const loadGradingScales = async () => {
    const response = await gradingScaleService.fetchAllGradingScales({
      params: {
        school_year_id: schoolYearId,
        term_id: termId,
      },
    });

    if (response.data) {
      let resData = response.data;
      if (!currentSchool.has_secondary_students) {
        resData = response.data.filter((d) => d.name !== "9-12");
      }
      setGradingScales(resData);
    }
  };

  const loadStudents = async () => {
    const response = await studentsService.fetchAllStudentsInSchool(
      {
        params: {
          school_id: schoolId,
          klass_id: activeKlass?.id,
          isPaginated: true,
        },
      },
      schoolYearId,
      page,
      "grade-level",
      "asc",
      "",
      10
    );

    if (response.data) {
      if (activeKlass) {
        const enrolledInKlass = response.data.filter(
          (student) =>
            student.klass_students &&
            student.klass_students.some((ks) => !ks.is_deleted)
        );
        const unenrolledInKlass = response.data.filter(
          (student) =>
            student.klass_students &&
            student.klass_students.some((ks) => ks.is_deleted)
        );

        setStudents(enrolledInKlass);
        setUnenrolledStudents(unenrolledInKlass);
      } else {
        setStudents(response.data);
      }

      setTotalPages(response.pagination.total_pages);
    }
  };

  const createKlass = async (data) => {
    const response = await klassesService.createKlass({
      ...{
        school_id: schoolId,
        students: selectedStudents,
        categories: categoryList.filter((c) => c.name && c.name !== undefined),
      },
      ...data,
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Class created.",
      });
      handleKlassesDrawer(false);
    }
  };

  const updateKlass = async (data) => {
    try {
      const response = await klassesService.updateKlass({
        ...{
          school_id: schoolId,
          klass_id: activeKlass.id,
          categories: categoryList.filter(
            (c) => c.name && c.name !== undefined
          ),
        },
        ...data,
        removedStudents,
        studentsToEnroll,
      });

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Class updated.",
        });
        handleKlassesDrawer(false);
      }
    } catch (error) {
      const ksdError = error.response.data.errors.klass_schedule_details;
      if (ksdError.length > 0) {
        ksdError.forEach((_error) => {
          methods.setError("klass_schedule_details", {
            type: "custom",
            message: _error.day_template.message,
          });
        });
      }
      setSnackBarOpen({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const onSubmit = async (data) => {
    if (level === "pk") {
      /* eslint-disable no-param-reassign */
      data.prek = true;
      if (!data.hasEvenlyWeightedCategories) {
        data.hasEvenlyWeightedCategories = true;
      }
      /* eslint-enable no-param-reassign */
    }
    if (!weightError) {
      if (activeKlass) {
        updateKlass(data);
      } else {
        createKlass(data);
      }
    }
  };

  const deleteClass = async () => {
    try {
      const response = await klassesService.remove(activeKlass.id, {
        params: {
          klass_schedule_id: classSchedule.id,
        },
      });

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Class deleted.",
        });
        handleKlassesDrawer(false);
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const getTerms = async () => {
    const response = await termService.fetchAllTermsInSchool({
      params: {
        school_id: schoolId,
        school_year_id: schoolYearId,
        order: "asc",
        order_by: "start_date",
        upcoming_terms: true,
      },
    });
    if (response.data) {
      setTerms(response.data.terms);
    }
  };

  const getKlassTerms = async () => {
    if (!activeKlass) return;
    const response = await klassTermService.fetchAll({
      params: {
        klass_id: activeKlass.id,
      },
    });
    if (response.data) {
      setKlassTerms(response.data);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      loadTeachers(),
      loadSubjects(),
      loadCourses(),
      loadClassSchedule(),
      loadGradingScales(),
      loadStudents(),
      loadDayTemplates(),
      getTerms(),
      getKlassTerms(),
    ]).then(() => {
      setLoading(false);
    });

    if (activeKlass) {
      const activeCategories = [];
      activeKlass.klass_categories
        .filter(
          (kc) =>
            (kc.grade_attendance_active === false &&
              kc.grade_attendance_total === null) ||
            kc.grade_attendance_active === true
        )
        .map((kc) =>
          activeCategories.push({
            id: kc.id,
            name: kc.category,
            weight: Number(kc.weight),
            assignmentCount: kc.assignment_count,
            attendance_category: kc.grade_attendance,
            grade_attendance_grading: kc.grade_attendance_grading,
            grade_attendance_total: kc.grade_attendance_total,
            grade_attendance_absence: kc.grade_attendance_absence,
            grade_attendance_tardy: kc.grade_attendance_tardy,
            grade_attendance_no_penalty: kc.grade_attendance_no_penalty,
          })
        );

      setTimeout(() => {
        setCategoryList(activeCategories);
      });
    }
  }, []);

  useEffect(() => {
    loadStudents();
  }, [page]);

  useEffect(() => {
    if (level === "pk") {
      // Get grading scale for TK-K and set it as default
      const gradingScale = gradingScales.find((scale) => scale.name === "TK-K");
      setValue("gradingScales", gradingScale?.id);
    }
  }, [gradingScales, level]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      {loading ? (
        <CircularProgress color="inherit" size={100} sx={loader} />
      ) : (
        <>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>
              {activeKlass ? "Edit Class" : "Add Class"}
            </Typography>
            <Stack direction="row">
              <Button
                sx={formButtons}
                onClick={() => handleKlassesDrawer(false)}
              >
                Cancel
              </Button>
              {activeKlass && (
                <GenericSubmitButton
                  text="Delete"
                  submittingText="Deleting..."
                  type="button"
                  onClick={() => deleteClass()}
                />
              )}
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Stack>
          <Box sx={formContainer}>
            <GeneralInfo
              teachers={teachers}
              subjects={subjects}
              courses={courses}
              teacherId={teacherId}
              activeKlass={activeKlass}
              setSelectedSubject={setSelectedSubject}
              level={level}
              setLevel={setLevel}
              currentSchool={currentSchool}
            />

            {level !== "pk" && (
              <>
                <Gradebook
                  categoryList={categoryList}
                  setCategoryList={setCategoryList}
                  weightError={weightError}
                  setWeightError={setWeightError}
                  activeKlass={activeKlass}
                />

                <Stack
                  sx={klassFormDivider}
                  direction="row"
                  justifyContent="space-between"
                >
                  <GradingScale
                    gradingScales={gradingScales}
                    activeKlass={activeKlass}
                    selectedSubject={selectedSubject}
                    subjects={subjects}
                  />
                  <DefaultAssignmentGrading activeKlass={activeKlass} />
                </Stack>
              </>
            )}

            <Terms
              terms={terms}
              termId={classSchedule?.term?.id}
              klassTerms={klassTerms}
              isEdit={!!activeKlass}
            />

            <KlassScheduleInfo
              classSchedule={classSchedule}
              dayTemplates={dayTemplates}
              selectedDayTemplate={selectedDayTemplate}
              setSelectedDayTemplate={setSelectedDayTemplate}
              activeKlass={activeKlass}
            />

            <Students
              schoolId={schoolId}
              students={students}
              unenrolledStudents={unenrolledStudents}
              studentsToEnroll={studentsToEnroll}
              setStudentsToEnroll={setStudentsToEnroll}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              activeKlass={activeKlass}
              classSchedule={classSchedule}
              removedStudents={removedStudents}
              setRemovedStudents={setRemovedStudents}
            />
          </Box>
        </>
      )}
    </form>
  );
}

export default function KlassFormContext(props) {
  const { activeKlass } = props;

  const methods = useForm({
    defaultValues: {
      klass_schedule_details: activeKlass?.klass_schedule_details,
    },
  });

  return (
    <FormProvider {...methods}>
      <KlassForm {...props} methods={methods} />
    </FormProvider>
  );
}
