import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import subjectService from "../../../service/subjectService";
import StudentsSubjectTable from "./StudentsSubjectTable";
import { subjectContainer } from "../../../views/Subject.styles";
import { loader } from "../../sharedStyles";

export default function Students() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(searchParams.get("count") || 25);
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "student");
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [totalStudents, setTotalStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const schoolId = params.school_id;
  const subjectId = params.subject_id;
  const klassesIds = searchParams.get("classes") || null;
  const location = useLocation();

  const getStudents = async () => {
    setLoading(true);
    const response = await subjectService.fetchSubjectStudents(subjectId, {
      params: {
        school_id: schoolId,
        term: searchParams.get("term"),
        klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
        page,
        orderBy,
        order,
        count,
      },
    });

    const queryPage = searchParams.get("page") || 1;
    const lastPage = response.pagination.total_pages;
    const currentPage = queryPage > lastPage ? lastPage : queryPage;
    setStudents(response.data);
    setTotalPages(lastPage);
    setPage(currentPage);
    setTotalStudents(response.pagination.total_count);
    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "student");
    setLoading(false);
  };

  useEffect(() => {
    getStudents();
  }, [page, order, orderBy, count, klassesIds, location, subjectId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={subjectContainer}>
      <StudentsSubjectTable
        students={students}
        totalPages={totalPages}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setPage={setPage}
        page={page}
        schoolId={schoolId}
        subjectId={subjectId}
        count={count}
        setCount={setCount}
        totalStudents={totalStudents}
      />
    </Box>
  );
}
