import React, { useState, useEffect, useContext } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useOutletContext } from "react-router-dom";
import { pageTabs } from "../sharedStyles";
import { parameterizeString } from "../../utils/constants/utils";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import { NavigationContext } from "../../context/NavigationContext";
import SchoolYearSelect from "../SchoolYearSelect";

export default function SchoolTabs({ schoolId }) {
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [, setSnackBarOpen, , , , , , ,] = useOutletContext();
  const { hasAnyPermissionType } = useContext(PermissionsContext);
  const { navigateTo } = useContext(NavigationContext);
  const hasAnyProfilePermissions = hasAnyPermissionType(
    APP_PERMISSIONS.SCHOOL_PROFILE
  );
  const hasAnySecurityPermissions = hasAnyPermissionType(
    APP_PERMISSIONS.SECURITY
  );
  const hasAnyGradingScalePermissions = hasAnyPermissionType(
    APP_PERMISSIONS.GRADING_SCALES
  );
  const hasAnySettingsPermissions = hasAnyPermissionType(
    APP_PERMISSIONS.SETTINGS
  );
  const hasAnyGPAPermissions = hasAnyPermissionType(APP_PERMISSIONS.GPA);
  const hasAnyGraduationPlansPermissions = hasAnyPermissionType(
    APP_PERMISSIONS.GRADUATION_PLANS
  );

  const formattedParams = () => {
    let paramsUrl = "?";
    const isGradingScaleTab = parameterizeString(
      window.location.pathname
    ).includes("grading-scales");
    if (selectedSchoolYear?.id && isGradingScaleTab) {
      paramsUrl = paramsUrl.concat(`&school_year=${selectedSchoolYear.id}`);
    }

    return paramsUrl;
  };

  const location = useLocation();
  const tabs = [
    ...(hasAnyProfilePermissions
      ? [
          {
            index: 0,
            label: "Profile",
            url: `/school/${schoolId}/school/profile`,
            requireSchoolYear: false,
          },
        ]
      : []),
    ...(hasAnySecurityPermissions
      ? [
          {
            index: 1,
            label: "Security",
            url: `/school/${schoolId}/school/security-groups`,
            requireSchoolYear: false,
          },
        ]
      : []),
    ...(hasAnyGradingScalePermissions
      ? [
          {
            index: 2,
            label: "Grading Scales",
            url: `/school/${schoolId}/school/grading-scales${formattedParams()}`,
            requireSchoolYear: true,
          },
        ]
      : []),
    ...(hasAnySettingsPermissions
      ? [
          {
            index: 3,
            label: "Settings",
            url: `/school/${schoolId}/school/settings`,
            requireSchoolYear: false,
          },
        ]
      : []),
    ...(hasAnyGPAPermissions
      ? [
          {
            index: 4,
            label: "GPA",
            url: `/school/${schoolId}/school/gpa`,
            requireSchoolYear: true,
          },
        ]
      : []),
    {
      index: 5,
      label: "Groups",
      url: `/school/${schoolId}/school/groups`,
      requireSchoolYear: true,
    },
    ...[
      {
        index: 6,
        label: "Custom Fields",
        url: `/school/${schoolId}/school/custom-fields`,
        requireSchoolYear: false,
      },
    ],
    ...(hasAnyGraduationPlansPermissions
      ? [
          {
            index: 7,
            label: "Graduation Plans",
            url: `/school/${schoolId}/school/graduation-plans`,
            requireSchoolYear: false,
          },
        ]
      : []),
  ].map((tab, index) => ({ ...tab, index }));
  const [tabValue, setTabValue] = useState(tabs[0]);

  const handleTabChange = (value, path) => {
    navigateTo(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.label)
      )
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [location]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Tabs value={tabValue?.index} sx={pageTabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            onClick={() => handleTabChange(tab.index, tab.url)}
          />
        ))}
      </Tabs>
      {tabValue?.requireSchoolYear ? (
        <Box>
          <SchoolYearSelect
            schoolId={schoolId}
            setSnackBarOpen={setSnackBarOpen}
            setSelectedSchoolYear={setSelectedSchoolYear}
          />
        </Box>
      ) : (
        false
      )}
    </Box>
  );
}
