import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import GroupList from "./GroupList";
import { groupTab, groupTypeContainer } from "./Group.styles";
import { mr15 } from "../../sharedStyles";
import groupService from "../../../service/groupService";
import AutoSave from "../../AutoSave";

export default function Group({ selectedGroup }) {
  const [activeGroup, setActiveGroup] = useState(selectedGroup);
  const [tabValue, setTabValue] = useState("add-members");
  const [typeValue, setTypeValue] = useState(null);
  const [addMemberList, setAddMemberList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [triggerAutoSave, setTriggerAutoSave] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [query, setQuery] = useState(searchParams.get("query") || "");

  const getAddMemberList = async () => {
    setLoading(true);
    const response = await groupService.fetchAddMembersList({
      params: {
        groupId: selectedGroup?.id,
        school_year_id: searchParams.get("school_year"),
        orderBy,
        order,
        query,
        filterBy: searchParams.get("filterBy"),
        operator: searchParams.get("operator"),
        value: searchParams.get("value"),
      },
    });

    if (response.data) {
      setAddMemberList(response.data);
      setLoading(false);
    }
  };

  const getGroup = async () => {
    setLoading(true);
    const response = await groupService.fetchGroup({
      params: {
        groupId: selectedGroup?.id,
        school_year_id: searchParams.get("school_year"),
        orderBy,
        order,
        query,
        filterBy: searchParams.get("filterBy"),
        operator: searchParams.get("operator"),
        value: searchParams.get("value"),
      },
    });

    if (response.data) {
      const defaultMemberList = response.data.members.map((m) => ({
        id: m.member_id,
        name: m.name,
        type: m.type,
      }));

      setMemberList(defaultMemberList);
      setActiveGroup(response.data.group);
      setTypeValue(response.data.group.group_type);
      setLoading(false);
    }
  };

  const handleTabChange = (value) => {
    setTabValue(value);
  };

  const handleTypeChange = (event) => {
    setTriggerAutoSave(true);
    setTypeValue(event.target.value);
  };

  const filterParams =
    searchParams.get("filterBy") !== null &&
    searchParams.get("operator") !== null &&
    searchParams.get("value") !== null;

  useEffect(() => {
    if (filterParams || searchParams.get("filtersCleared")) {
      searchParams.delete("filtersCleared");
      setSearchParams(searchParams);
      getAddMemberList();
      getGroup();
    }
  }, [
    searchParams.get("filterBy"),
    searchParams.get("operator"),
    searchParams.get("value"),
  ]);

  useEffect(() => {
    setTriggerAutoSave(false);
  }, [typeValue, memberList.length]);

  useEffect(() => {
    getAddMemberList();
    getGroup();
  }, [
    orderBy,
    order,
    query,
    tabValue,
    selectedGroup,
    searchParams.get("school_year"),
  ]);

  return (
    <Box>
      <Stack sx={groupTypeContainer} direction="row" alignItems="center">
        <Typography sx={mr15}>Type:</Typography>
        <FormControl>
          <RadioGroup
            onChange={(e) => handleTypeChange(e)}
            row
            value={typeValue}
          >
            <FormControlLabel value="staff" control={<Radio />} label="Staff" />
            <FormControlLabel
              value="student"
              control={<Radio />}
              label="Student"
            />
            <FormControlLabel
              value="guardian"
              control={<Radio />}
              label="Guardian"
            />
            <FormControlLabel
              value="communication"
              control={<Radio />}
              label="Communication"
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Box>
        <Tabs
          value={tabValue}
          TabIndicatorProps={{ style: { background: "#2196F3" } }}
        >
          <Tab
            label={`Members (${memberList.length})`}
            value="members"
            sx={groupTab}
            onClick={() => handleTabChange("members")}
          />
          <Tab
            label="Add Members"
            value="add-members"
            sx={groupTab}
            onClick={() => handleTabChange("add-members")}
          />
        </Tabs>

        <Box>
          <GroupList
            tabValue={tabValue}
            addMemberList={addMemberList}
            memberList={memberList}
            setMemberList={setMemberList}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            order={order}
            setOrder={setOrder}
            query={query}
            setQuery={setQuery}
            setTriggerAutoSave={setTriggerAutoSave}
            loading={loading}
          />
        </Box>
      </Box>

      {triggerAutoSave && (
        <AutoSave
          watcher={{
            groupId: activeGroup?.id,
            selected_members: memberList,
            group_type: typeValue || activeGroup?.group_type,
            school_year_id: searchParams.get("school_year"),
          }}
          saveMethod={groupService.updateGroup}
          successMessage="Group saved"
          progressMessage="Saving group..."
          afterSaving={() => setTriggerAutoSave(false)}
        />
      )}
    </Box>
  );
}
