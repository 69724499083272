import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  Drawer,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import GraduationPlanForm from "./GraduationPlanForm";
import { CommonContext } from "../../../context/CommonContext";
import { fetchGraduationPlans } from "../../../service/graduationPlanService";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { loader, rightDrawerContainer } from "../../sharedStyles";
import graduationPlansContainer from "./GraduationPlans.style";

function RowMenu({ planId, schoolId, handleEdit }) {
  const { hasPermission } = useContext(PermissionsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit(planId);
  };

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.GRADUATION_PLANS,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {hasManagePermission && (
          <MenuItem onClick={handleEditClick}>Edit plan</MenuItem>
        )}

        <Link to={`/school/${schoolId}/school/graduation-plans/${planId}`}>
          <MenuItem>View requirements</MenuItem>
        </Link>
      </Menu>
    </>
  );
}

export default function SchoolGraduationPlans() {
  const commonContext = useContext(CommonContext);
  const params = useParams();
  const schoolId = params.school_id;
  const [plans, setPlans] = useState();
  const [loading, setLoading] = useState(true);
  const [editPlanId, setEditPlanId] = useState(null);

  const fetchPlans = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!loading) setLoading(true);
    fetchGraduationPlans().then((response) => setPlans(response.data));
  };

  const handleEdit = (planId) => {
    setEditPlanId(planId);
  };

  useEffect(() => {
    if (commonContext.processStarted) fetchPlans();
  }, [commonContext.processStarted]);

  useEffect(() => {
    if (plans) {
      setLoading(false);
      commonContext.cleanFireProcessStarted();
    } else {
      commonContext.fireProcessStarted();
    }
  }, [plans]);

  if (loading) return <CircularProgress sx={loader} size={100} />;

  if (!plans) return <span>Error loading plans.</span>;

  if (plans.length === 0)
    return (
      <Box sx={graduationPlansContainer}>
        <Typography
          variant="h5"
          sx={{ my: 8, textAlign: "center", color: "disabled.main" }}
        >
          No graduation plans.
        </Typography>
      </Box>
    );

  return (
    <>
      <Box sx={graduationPlansContainer}>
        <Box className="plan-container">
          <TableContainer sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>DESCRIPTION</TableCell>
                  <TableCell align="center">COURSES</TableCell>
                  <TableCell align="center">STUDENTS</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map((plan) => (
                  <TableRow hover key={plan.id}>
                    <TableCell>
                      <Link
                        to={`/school/${schoolId}/school/graduation-plans/${plan.id}`}
                      >
                        {plan.name}
                      </Link>
                    </TableCell>
                    <TableCell>{plan.description}</TableCell>
                    <TableCell align="center">
                      {plan.requirements_count}
                    </TableCell>
                    <TableCell align="center">{plan.students_count}</TableCell>
                    <TableCell>
                      {plan.active ? (
                        <Typography
                          variant="body"
                          sx={{ color: "success.main" }}
                        >
                          Active
                        </Typography>
                      ) : (
                        <Typography
                          variant="body"
                          sx={{ color: "disabled.main" }}
                        >
                          Inactive
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <RowMenu
                        planId={plan.id}
                        schoolId={schoolId}
                        handleEdit={handleEdit}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Drawer anchor="right" open={Boolean(editPlanId)}>
        <Box sx={rightDrawerContainer}>
          <GraduationPlanForm
            schoolId={schoolId}
            planId={editPlanId}
            handleDrawer={() => setEditPlanId(null)}
          />
        </Box>
      </Drawer>
    </>
  );
}
