import React from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import GroupFilters from "./GroupFilters";
import GroupSearch from "./GroupSearch";
import {
  filtersContainer,
  groupTableCell,
  groupTableHead,
} from "./GroupList.styles";
import { loader, mt15, mt25 } from "../../sharedStyles";

export default function GroupList({
  setTriggerAutoSave,
  memberList,
  setMemberList,
  addMemberList,
  searchParams,
  setSearchParams,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  query,
  setQuery,
  tabValue,
  loading,
}) {
  const renderList = tabValue === "add-members" ? addMemberList : memberList;

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={{ visibility: "hidden" }} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleSelectMembers = (event, member) => {
    setTriggerAutoSave(true);
    if (member === "all") {
      if (event.target.checked) {
        const newSelecteds = renderList.map((m) => ({
          id: m.id,
          type: m.type,
          name: m.name,
        }));
        setMemberList(newSelecteds);
        return;
      }

      setMemberList([]);
    } else {
      if (event.target.checked) {
        const newSelecteds = [...memberList, member];
        setMemberList(newSelecteds);
        return;
      }

      setMemberList(
        memberList.filter(
          (s) => !(s.id === member.id && s.type === member.type)
        )
      );
    }
  };

  const isAllChecked =
    tabValue === "add-members"
      ? memberList.length === addMemberList.length && renderList.length > 0
      : true;

  return (
    <Box>
      <Stack
        sx={filtersContainer}
        direction="row"
        justifyContent="space-between"
        alignItems="end"
      >
        <GroupFilters />
        <GroupSearch
          query={query}
          setQuery={setQuery}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Stack>
      {loading && <CircularProgress sx={loader} size={100} />}
      {renderList.length === 0 ? (
        <Typography align="center" sx={mt25}>
          No members in this group.
        </Typography>
      ) : (
        <TableContainer sx={[mt15, { height: "65vh" }]}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={groupTableHead} padding="checkbox">
                  <Checkbox
                    checked={isAllChecked}
                    onClick={(event) => handleSelectMembers(event, "all")}
                  />
                </TableCell>
                <TableCell
                  sx={groupTableHead}
                  onClick={() => handleRequestSort("name")}
                >
                  <Typography sx={{ textTransform: "uppercase" }}>
                    Name {renderSortChevron("name")}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={groupTableHead}
                  onClick={() => handleRequestSort("type")}
                >
                  <Typography sx={{ textTransform: "uppercase" }}>
                    Type {renderSortChevron("type")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderList.map((member, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <TableRow key={index}>
                  <TableCell sx={groupTableCell} padding="checkbox">
                    <Checkbox
                      onClick={(event) =>
                        handleSelectMembers(event, {
                          id: member.id,
                          member: member.name,
                          type: member.type,
                        })
                      }
                      checked={
                        !!memberList.find(
                          (m) => m.id === member.id && m.type === member.type
                        )
                      }
                    />
                  </TableCell>
                  <TableCell sx={groupTableCell}>
                    <Typography>{member.name}</Typography>
                  </TableCell>
                  <TableCell sx={groupTableCell}>
                    <Typography>{member.type}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
