import axios from "../utils/axios-util";

const schoolEndpoint = "/api/schools";

const fetchSchool = async (schoolId) => {
  const { data } = await axios.get(`${schoolEndpoint}/${schoolId}`, {
    params: { school_id: schoolId },
  });
  if (!data.data) {
    console.error("Error fetching school from the API");
    return [];
  }
  return data;
};

const fetchSchoolProfile = async (schoolId) => {
  const { data } = await axios.get(`${schoolEndpoint}/profile/${schoolId}`, {
    params: { school_id: schoolId },
  });
  if (!data.data) {
    console.error("Error fetching school from the API");
    return [];
  }
  return data;
};

const updateSchool = async (guardianId, body) => {
  const { data } = await axios.put(`${schoolEndpoint}/${guardianId}`, body);
  if (!data.data) {
    console.error("Error fetching school from the API");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const fetchSchoolYears = async (params) =>
  axios.get(`${schoolEndpoint}/school_years`, { params });

const fetchAllSchools = async () => {
  const { data } = await axios.get(`${schoolEndpoint}`);
  if (!data.data) {
    console.error("Error fetching school from the API");
    return [];
  }
  return data;
};

const updateSchoolGradebookVersion = async (schoolId, version) => {
  const { data } = await axios.patch(
    `${schoolEndpoint}/${schoolId}/update_gradebook_version`,
    {
      gradebook_version: version,
    }
  );
  if (!data) {
    console.error("Error updating gradebook version");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const updateSchoolPeriodAttendance = async (
  schoolId,
  periodAttendance,
  halfDayMinutes,
  fullDayMinutes,
  periodAttendanceGrades
) => {
  const { data } = await axios.patch(
    `${schoolEndpoint}/${schoolId}/update_period_attendance`,
    {
      period_attendance: periodAttendance,
      half_day_minutes: halfDayMinutes,
      full_day_minutes: fullDayMinutes,
      period_attendance_grades: periodAttendanceGrades,
    }
  );
  if (!data) {
    console.error("Error updating period attendance.");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const syncDataRollup = async (schoolId) => {
  const response = await axios.post(`${schoolEndpoint}/datarollup_sync`, {
    id: schoolId,
  });
  if (!response.status === 200) {
    console.error("Error syncing data rollup from the API");
    return [];
  }
  return response;
};

export default {
  fetchSchool,
  updateSchool,
  fetchSchoolYears,
  fetchAllSchools,
  fetchSchoolProfile,
  updateSchoolGradebookVersion,
  syncDataRollup,
  updateSchoolPeriodAttendance,
};
