import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Drawer,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { mr10, py30, loader, rightDrawerContainer } from "../../sharedStyles";
import {
  hidden,
  listCell,
  listCellName,
  listHead,
  listName,
  listTable,
  settingsCell,
} from "./ClassScheduleDetails.styles";
import KlassForm from "../../Klass/KlassForm";
import teachersService from "../../../service/staffsService";
import SnackBarNotification from "../../SnackBarNotification";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import klassesService from "../../../service/klassesService";

export default function ClassScheduleListView({
  classes,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  searchParams,
  setSearchParams,
  schoolId,
  drawerStatusEdit,
  setDrawerStatusEdit,
  schoolYearId,
  loading,
  currentSchool,
  classSchedule,
}) {
  const [teacher, setTeacher] = useState(null);
  const [activeKlass, setActiveKlass] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASS_SCHEDULES,
    PERMISSION_TYPES.MANAGE
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "start_date") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const getActiveKlass = async (klass) => {
    const response = await klassesService.fetchKlass(klass);
    if (response.data) {
      response.data.klass_schedule_details =
        response.data.klass_schedule_details.filter(
          (detail) => detail.klass_schedule.term_id === classSchedule?.term?.id
        );

      setActiveKlass(response.data);
    }
  };

  const handleDrawer = (status, klassId) => {
    setDrawerStatusEdit(status);
    if (status) {
      getActiveKlass(klassId);
    }
  };

  const getTeacherByCurrentUser = async () => {
    const response = await teachersService.fetchByCurrentUser({});
    if (response.data) {
      setTeacher(response.data.teacher);
    }
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen({ open: false });
  };

  useEffect(() => {
    getTeacherByCurrentUser();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        {classes?.length > 0 ? (
          <TableContainer sx={py30}>
            <Table sx={listTable}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={listHead}
                    onClick={() => handleRequestSort("name")}
                  >
                    <Typography sx={listName}>
                      NAME {renderSortChevron("name")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={listHead}>
                    <Typography sx={mr10}>ABBREVIATION</Typography>
                  </TableCell>
                  <TableCell
                    sx={listHead}
                    onClick={() => handleRequestSort("subject")}
                  >
                    <Typography sx={mr10}>
                      SUBJECT / COURSE {renderSortChevron("subject")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={listHead}>
                    <Typography sx={mr10}>STUDENTS</Typography>
                  </TableCell>
                  <TableCell sx={listHead}>
                    <Typography sx={mr10}>DAY TEMPLATE, TRACK</Typography>
                  </TableCell>
                  <TableCell sx={listHead} />
                </TableRow>
              </TableHead>
              <TableBody>
                {classes?.map((_class) => (
                  <TableRow hover key={_class.id}>
                    <TableCell sx={listCellName}>
                      <Typography>{_class?.name}</Typography>
                    </TableCell>
                    <TableCell sx={listCell}>
                      <Typography>{_class?.abbreviation}</Typography>
                    </TableCell>
                    <TableCell sx={listCell}>
                      <Typography>
                        {_class?.course?.name || _class?.subject?.name}
                      </Typography>
                    </TableCell>
                    <TableCell sx={listCell}>
                      <Typography>{_class?.enrolled_students || 0}</Typography>
                    </TableCell>
                    <TableCell sx={listCell}>
                      <Typography>
                        {_class?.klass_schedule_details?.map(
                          (detail, index) => {
                            let details = `${detail.period.day_template.abbreviation}, ${detail.period.track}, ${detail.period.name}`;
                            if (
                              index <
                              _class.klass_schedule_details.length - 1
                            ) {
                              details = details.concat(" | ");
                            }
                            return details;
                          }
                        )}
                      </Typography>
                    </TableCell>
                    {managePermission && (
                      <TableCell
                        sx={settingsCell}
                        onClick={() => handleDrawer(true, _class.id)}
                      >
                        <SettingsOutlinedIcon />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">No schedules found</Typography>
        )}
      </Grid>
      <Drawer anchor="right" open={drawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {activeKlass ? (
            <KlassForm
              teacherId={teacher.id}
              schoolId={schoolId}
              handleKlassesDrawer={handleDrawer}
              activeKlass={activeKlass}
              setSnackBarOpen={setSnackBarOpen}
              schoolYearId={schoolYearId}
              currentSchool={currentSchool}
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={handleSnackBarClose}
      />
    </Grid>
  );
}
