import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Typography, CircularProgress } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import GradebookTable from "./GradebookTable";
import GradebookDaySelect from "./GradebookDaySelect";
import { gradebookInner, gradebookEmptyWeek } from "./Gradebook.styles";
import { subjectContainer } from "../../../views/Subject.styles";
import assignmentStudentService from "../../../service/assignmentStudentService";
import { loader } from "../../sharedStyles";
import { QuickBarContext } from "../../../context/QuickBarContext";

export default function Gradebook() {
  const subjectId = useParams().subject_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [studentsWithoutAssignments, setStudentsWithoutAssignments] = useState(
    []
  );
  const [assignmentDates, setAssignmentDates] = useState([]);
  const [grades, setGrades] = useState({});
  const [categories, setCategories] = useState({});
  const [categoryScores, setCategoryScores] = useState({});
  const [totalScores, setTotalScores] = useState({});
  const [loading, setLoading] = useState(false);
  const [maxScoreChanged, setMaxScoreChanged] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentCell, setCurrentCell] = useState("");
  const [ungradedOnly, setUngradedOnly] = useState(false);
  const quickbarContext = useContext(QuickBarContext);

  const klassesIds = searchParams.get("classes") || null;

  const noCategoryKey = "No Category";

  const renderTheStudents = (dbAssignmentStudents) => {
    const studentsMap = {};
    const studentsWithoutAssignmentsMap = {};
    const assignmentDatesMap = {};
    const gradesMap = {};
    const emptyCategories = [];

    if (dbAssignmentStudents.assignments_students) {
      dbAssignmentStudents.assignments_students.forEach((as) => {
        // Build students map
        studentsMap[`${as.student.id}-${as.assignment.klass.id}`] = as.student;
        studentsMap[`${as.student.id}-${as.assignment.klass.id}`].klass =
          as.assignment.klass;

        // Build assignment dates map
        if (
          !assignmentDatesMap[as.assignment.assigned_date] &&
          (!ungradedOnly || (ungradedOnly && as.score === null))
        ) {
          assignmentDatesMap[as.assignment.assigned_date] = {
            assignedDate: "",
            assignments: {},
          };
        }
        // Do not add assignment if all students have scores and ungradedOnly is true
        if (!ungradedOnly || (ungradedOnly && as.score === null)) {
          assignmentDatesMap[as.assignment.assigned_date].assignedDate =
            as.assignment.assigned_date;
          assignmentDatesMap[as.assignment.assigned_date].assignments[
            as.assignment.id
          ] = as.assignment;
        }

        // Build grades map
        gradesMap[
          `${as.student.id}-${as.assignment.id}-${as.assignment.klass.id}`
        ] = as;

        if (as.score === null) {
          emptyCategories.push(as.assignment.category);
        }
      });

      // Sorting dates
      const assignmentDatesArray = Object.values(assignmentDatesMap).sort(
        (a, b) => new Date(a.assignedDate) - new Date(b.assignedDate)
      );

      // Sorting students
      const studentsArray = Object.values(studentsMap).sort((a, b) =>
        `${a.klass.abbreviation}${a.first_name}${a.middle_name}${a.last_name}`.localeCompare(
          `${b.klass.abbreviation}${b.first_name}${a.middle_name}${b.last_name}`
        )
      );

      setAssignmentDates(assignmentDatesArray);
      setStudents(studentsArray);
      setGrades(gradesMap);
    }

    if (dbAssignmentStudents.students_without_assignments) {
      dbAssignmentStudents.students_without_assignments.forEach((aws) => {
        // eslint-disable-next-line array-callback-return
        aws.klasses.map((k) => {
          const { klasses, ...rest } = aws;

          studentsWithoutAssignmentsMap[`${aws.id}-${k.id}`] = rest;
          studentsWithoutAssignmentsMap[`${aws.id}-${k.id}`].klass = k;
          studentsWithoutAssignmentsMap[
            `${aws.id}-${k.id}`
          ].klass.klass_categories.map((ok) =>
            emptyCategories.push(`${ok.category}`)
          );
        });
      });

      setStudentsWithoutAssignments(
        Object.values(studentsWithoutAssignmentsMap)
      );
    }

    if (
      dbAssignmentStudents.category_scores &&
      klassesIds?.split(",").length === 1
    ) {
      const categoriesScoreMap = {};
      const categoriesMap = {};

      Object.values(dbAssignmentStudents.category_scores)
        .filter((cs) => cs.klass_id === Number(klassesIds?.split(",")[0]))
        .forEach((score) => {
          if (score.category === null) {
            categoriesMap[noCategoryKey] = noCategoryKey;
            categoriesScoreMap[
              `${score.student_id}-${score.klass_id}-${noCategoryKey}`
            ] = score.category_score;
          } else {
            categoriesMap[score.category] = score.category;
            categoriesScoreMap[
              `${score.student_id}-${score.klass_id}-${score.category}`
            ] = score.category_score;
          }
        });

      const allCategories = Object.values(categoriesMap)
        .concat(emptyCategories)
        .sort();
      const uniqueCategories = [...new Set(allCategories)].filter(
        (category) => category !== null
      );

      setCategories(uniqueCategories);
      setCategoryScores(categoriesScoreMap);
    } else {
      setCategories([]);
      setCategoryScores([]);
    }

    setTotalScores(dbAssignmentStudents.total_scores);
    setPageLoading(false);
  };

  const getAssignmentStudents = async () => {
    setPageLoading(true);
    assignmentStudentService
      .fetchAssignmentStudents({
        params: {
          date: searchParams.get("date"),
          klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
          subject_id: subjectId,
          term_id: searchParams.get("term"),
        },
      })
      .then((dbAssignmentStudents) => {
        renderTheStudents(dbAssignmentStudents);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderStudents = [...students, ...studentsWithoutAssignments].filter(
    (allStudents, index, array) =>
      array.findIndex(
        (s) => s.klass.id === allStudents.klass.id && s.id === allStudents.id
      ) === index
  );

  renderStudents.sort((a, b) =>
    `${a.klass.abbreviation}${a.last_name}${a.first_name}${a.middle_name}`.localeCompare(
      `${b.klass.abbreviation}${b.last_name}${b.first_name}${a.middle_name}`
    )
  );

  useEffect(() => {
    /* eslint-disable prefer-const */
    let timer;
    setLoading(true);
    timer = setTimeout(() => {
      getAssignmentStudents();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [
    searchParams.get("date"),
    klassesIds,
    subjectId,
    quickbarContext.assignmentDrawer,
  ]);

  useEffect(() => {
    setLoading(true);
    getAssignmentStudents();
    setMaxScoreChanged(false);
  }, [maxScoreChanged]);

  useEffect(() => {
    getAssignmentStudents();
  }, [ungradedOnly]);

  return (
    <Box sx={subjectContainer}>
      {pageLoading ? (
        <CircularProgress sx={loader} size={100} />
      ) : (
        <Container maxWidth="xl" sx={gradebookInner}>
          <GradebookDaySelect
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />

          {renderStudents && renderStudents.length > 0 ? (
            <GradebookTable
              assignmentDates={assignmentDates}
              renderStudents={renderStudents}
              grades={grades}
              klassesIds={klassesIds}
              categories={categories}
              setCategories={setCategories}
              categoryScore={categoryScores}
              totalScores={totalScores}
              setGrades={setGrades}
              setCategoryScores={setCategoryScores}
              setTotalScores={setTotalScores}
              loading={loading}
              setLoading={setLoading}
              currentCell={currentCell}
              setCurrentCell={setCurrentCell}
              noCategoryKey={noCategoryKey}
              setMaxScoreChanged={setMaxScoreChanged}
              ungradedOnly={ungradedOnly}
              setUngradedOnly={setUngradedOnly}
            />
          ) : (
            <Box sx={gradebookEmptyWeek}>
              <Typography>No assignments for this week.</Typography>
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
}
