/* eslint no-underscore-dangle: 0 */
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  Stack,
  Button,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  IconButton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  loader,
} from "../../../sharedStyles";
import GenericSubmitButton from "../../../GenericSubmitButton";
import {
  createGraduationRequirement,
  fetchGraduationRequirement,
  updateGraduationRequirement,
  deleteGraduationRequirement,
} from "../../../../service/graduationRequirementService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { CommonContext } from "../../../../context/CommonContext";

const formFieldStyle = {
  "& .MuiFormHelperText-root": {
    position: "absolute",
    bottom: "-20px",
  },
  mb: 1,
};

export default function PlanRequirementForm({ requirementId, handleDrawer }) {
  const { id } = useParams();
  const planId = id ? Number(id) : null;
  const commonContext = useContext(CommonContext);
  const snackbarContext = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      graduation_credits: [
        { id: null, course_name: "", credits: 1, _destroy: false },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "graduation_credits",
  });

  const saveRequest = (data) =>
    requirementId
      ? updateGraduationRequirement(planId, requirementId, data)
      : createGraduationRequirement(planId, data);

  const onSubmit = (formData) => {
    if (
      formData.graduation_credits.filter((credit) => !credit._destroy)
        .length === 0
    ) {
      setError("graduation_credits", {
        type: "manual",
        message: "At least one course is required",
      });

      return;
    }

    setLoading(true);

    saveRequest({ graduation_requirement: formData })
      .then(() => {
        handleDrawer(false);
        commonContext.fireProcessStarted();
        snackbarContext.setSnackbar({
          message: "Graduation requirements saved.",
          severity: "success",
          open: true,
        });
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
        Object.keys(data).forEach((key) =>
          setError(key, { type: "custom", message: data[key] })
        );
      });
  };

  const fetchRequirement = () => {
    setLoading(true);

    fetchGraduationRequirement(planId, requirementId)
      .then((response) => {
        const { data } = response;
        Object.keys(data).forEach((key) => {
          if (key === "graduation_credits") {
            const updatedCredits = data[key].map((credit) => ({
              ...credit,
              _destroy: false,
            }));
            setValue(key, updatedCredits);
          } else {
            setValue(key, data[key]);
          }
        });
        setLoading(false);
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to fetch graduation requirements.",
          severity: "error",
          open: true,
        });
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    setDialogOpen(false);
    setLoading(true);

    deleteGraduationRequirement(planId, requirementId)
      .then(() => {
        handleDrawer(false);
        commonContext.fireProcessStarted();
        snackbarContext.setSnackbar({
          message: "Graduation requirements deleted.",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to delete graduation requirements.",
          severity: "error",
          open: true,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (requirementId) fetchRequirement(requirementId);
  }, [requirementId]);

  if (loading) return <CircularProgress sx={loader} size={100} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>
          {requirementId ? "Edit" : "Add"} requirements
        </Typography>

        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawer(false)}>
            Cancel
          </Button>

          {requirementId && (
            <Button sx={formButtons} onClick={handleDelete}>
              Delete
            </Button>
          )}

          <GenericSubmitButton text="Save" onClick={clearErrors} />
        </Stack>
      </Stack>

      <Box sx={formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              label="Subject name"
              variant="outlined"
              {...register("name", { required: "Name is required" })}
              error={Boolean(errors?.name)}
              helperText={errors?.name?.message}
              autoComplete="subject-name"
              sx={formFieldStyle}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              label="Required Credits"
              variant="outlined"
              {...register("required_credits", {
                required: "Required credits is mandatory",
              })}
              error={Boolean(errors?.required_credits)}
              helperText={errors?.required_credits?.message}
              type="number"
              InputProps={{
                inputProps: { min: "0.01", step: "0.01", lang: "en-US" },
              }}
              inputMode="numeric"
              autoComplete="off"
              sx={formFieldStyle}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Instructions"
              variant="outlined"
              multiline
              rows={5}
              {...register("instructions")}
              sx={formFieldStyle}
            />
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ my: 5 }}>
          Courses
        </Typography>

        {errors.graduation_credits && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {errors.graduation_credits.message === "is invalid"
              ? "Please check if courses names and credits are valid."
              : errors.graduation_credits.message ||
                "Please check errors and try again."}
          </Alert>
        )}

        <Stack spacing={2}>
          {fields.map(
            (field, index) =>
              !field._destroy && (
                <Box
                  key={field.id}
                  sx={{ pb: 2, borderBottom: "1px solid #e0e0e0" }}
                >
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Course name"
                        autoComplete="course-name"
                        {...register(
                          `graduation_credits.${index}.course_name`,
                          {
                            required: "Course name is required",
                          }
                        )}
                        error={Boolean(
                          errors?.graduation_credits?.[index]?.course_name
                        )}
                        helperText={
                          errors?.graduation_credits?.[index]?.course_name
                            ?.message
                        }
                        sx={formFieldStyle}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Credits"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        InputProps={{
                          inputProps: {
                            min: "0.01",
                            step: "0.01",
                            lang: "en-US",
                          },
                        }}
                        {...register(`graduation_credits.${index}.credits`, {
                          required: "Credits are required",
                          min: { value: 0.01, message: "Must be positive" },
                        })}
                        error={Boolean(
                          errors?.graduation_credits?.[index]?.credits
                        )}
                        helperText={
                          errors?.graduation_credits?.[index]?.credits?.message
                        }
                        sx={formFieldStyle}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          const currentCredits =
                            getValues("graduation_credits");
                          const updatedCredits = [...currentCredits];
                          updatedCredits[index] = {
                            ...updatedCredits[index],
                            _destroy: true,
                          };
                          setValue("graduation_credits", updatedCredits);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              )
          )}
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            color="info"
            sx={{ textTransform: "capitalize" }}
            onClick={() =>
              append({ id: null, course_name: "", credits: 1, _destroy: false })
            }
          >
            Add Course
          </Button>
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this graduation requirement?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
