import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  bgWhite,
  formButtons,
  formCheckBox,
  formContainer,
  formHeader,
  formTitle,
  pt0,
  textUpper,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import { klassFormRow } from "../../Klass/KlassForm.styles";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import ErasableTextField from "../../ErasableTextField";
import {
  addTranscriptFormSubject,
  addTranscriptFormSubject2,
  addTranscriptKlassName,
  addTranscriptRecordSelect,
  addTranscriptRecordSelect2,
  classNameTranscriptRecord,
  creditsEarnedTranscriptRecord,
  gradeGPARecord,
  gradeTranscriptRecord,
  transcriptDivider,
} from "./AddTranscriptRecord.style";
import courseService from "../../../service/courseService";
import transcriptService from "../../../service/transcriptService";
import { QuickBarContext } from "../../../context/QuickBarContext";
import departmentsService from "../../../service/departmentsService";
import TextEditor from "../../TextEditor/TextEditor";
import AlertDialog from "../../AlertDialog";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function AddTranscriptRecord({
  handleTranscriptDrawer,
  setSnackBarOpen,
  activeTranscriptRecord,
}) {
  const defaultValues = activeTranscriptRecord
    ? {
        ...activeTranscriptRecord,
        school_year: activeTranscriptRecord.transcript.school_year,
        grade_level: activeTranscriptRecord.transcript.grade_level,
        is_manual_entry: true,
      }
    : {
        is_manual_entry: true,
        school_year: "",
        summer_school: false,
        school_granting_credit: "",
        grade_level: "",
        instructor: "",
        department_id: "",
        course_id: "",
        course_name: "",
        not_nad_course: false,
        state_id: "",
        transfer_credit: false,
        class_name: "",
        s1_grade: "",
        s1_percent: "",
        s1_credits_earned: "",
        s1_credits_possible: "",
        s1_average: "",
        s1_gpa: "",
        s1_ugpa: "",
        s2_grade: "",
        s2_percent: "",
        s2_credits_earned: "",
        s2_credits_possible: "",
        s2_average: "",
        s2_gpa: "",
        s2_ugpa: "",
        final_grade: "",
        final_percent: "",
        final_credits_earned: "",
        final_credits_possible: "",
        final_average: "",
        final_gpa: "",
        final_ugpa: "",
        absent: "",
        tardy: "",
        is_passing: false,
        calculate_in_transcript: false,
        notes: "",
      };
  const methods = useForm({ defaultValues });
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [notNadCourse, setIsNadCourse] = useState(defaultValues.not_nad_course);
  const quickbarContext = useContext(QuickBarContext);
  const params = useParams();
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleCloseAlertDialog = () => {
    setAlertDialogOpen(false);
  };

  const onNotesChange = (data) => {
    methods.setValue("notes", data, { shouldDirty: true });
  };

  const createTranscript = async (data) => {
    const body = {
      ...data,
      ...{
        school_id: params.school_id,
        student_id: params.student_slug,
      },
      not_nad_course: data.not_nad_course,
    };

    let response;

    if (activeTranscriptRecord) {
      body.id = activeTranscriptRecord.id;
      response = await transcriptService.updateTranscriptRecord(body);
    } else {
      response = await transcriptService.createTranscript(body);
    }

    quickbarContext.fireLoadTranscripts();

    if (response.data) {
      let message = "Transcript created.";
      if (activeTranscriptRecord) {
        message = "Transcript updated.";
      }

      setSnackBarOpen({
        open: true,
        message,
      });
      handleTranscriptDrawer(false);
    }
  };

  const deleteTranscript = async () => {
    const response = await transcriptService
      .deleteTranscript(activeTranscriptRecord?.transcript?.id)
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response) {
      quickbarContext.fireLoadTranscripts();

      snackbarContext.setSnackbar({
        message: "Transcript deleted.",
        severity: "success",
        open: true,
      });
      handleTranscriptDrawer(false);
    }
  };

  const onSubmit = async (data) => {
    await createTranscript(data);
  };

  const loadCourses = () => {
    (async () => {
      const response = await courseService.fetchAllCourses({
        params: {
          school_id: params.school_id,
          order: "asc",
          orderBy: "name",
        },
      });
      if (response.data) {
        setCourses(
          response.data.filter(
            (course) => course.level === "secondary" && course.active === true
          )
        );
      }
    })();
  };

  const loadDepartments = () => {
    (async () => {
      const response = await departmentsService.fetchAll();
      if (response.data) {
        setDepartments(response.data.filter((f) => f.active === true));
      }
    })();
  };

  useEffect(() => {
    Promise.all([loadCourses(), loadDepartments()]);
    methods.setValue("is_manual_entry", true, {
      shouldDirty: true,
    });
    methods.trigger("is_manual_entry");
  }, []);

  const handleCheckboxChange = (event) => {
    setIsNadCourse(event.target.checked);
  };

  const { hasPermission } = useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_TRANSCRIPTS,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {activeTranscriptRecord
              ? "Edit Transcript Record"
              : "Add Transcript Record"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleTranscriptDrawer(false)}
            >
              Cancel
            </Button>
            {activeTranscriptRecord && managePermission && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => {
                  setAlertDialogOpen(true);
                }}
              />
            )}
            {managePermission && (
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            )}
          </Stack>
        </Stack>
        <Box sx={formContainer}>
          <Box sx={pt0}>
            <Box sx={klassFormRow}>
              <FormControl
                fullWidth
                sx={formCheckBox}
                style={{ justifyContent: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      defaultChecked={defaultValues.is_manual_entry}
                      {...methods.register("is_manual_entry", {})}
                    />
                  }
                  label={<Typography>Manual Entry</Typography>}
                />
              </FormControl>
              <Stack direction="row">
                <Box sx={addTranscriptRecordSelect}>
                  <FormControl
                    fullWidth
                    sx={addTranscriptFormSubject}
                    error={methods.formState.errors?.school_year}
                  >
                    <InputLabel id="school_year" shrink sx={bgWhite}>
                      Year*
                    </InputLabel>
                    <Select
                      labelId="school_year"
                      id="school_year"
                      defaultValue={defaultValues.school_year}
                      {...methods.register("school_year", {
                        required: requiredMessage,
                      })}
                    >
                      <MenuItem value="2018-2019">2018-2019</MenuItem>
                      <MenuItem value="2019-2020">2019-2020</MenuItem>
                      <MenuItem value="2020-2021">2020-2021</MenuItem>
                      <MenuItem value="2021-2022">2021-2022</MenuItem>
                      <MenuItem value="2022-2023">2022-2023</MenuItem>
                      <MenuItem value="2023-2024">2023-2024</MenuItem>
                      <MenuItem value="2024-2025">2024-2025</MenuItem>
                    </Select>
                  </FormControl>

                  {methods.formState.errors?.school_year &&
                    renderError(methods.formState.errors?.school_year.message)}
                </Box>

                <Box>
                  <FormControl fullWidth sx={formCheckBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={defaultValues.summer_school}
                          {...methods.register("summer_school", {})}
                        />
                      }
                      label={<Typography>Summer School</Typography>}
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={addTranscriptKlassName}>
                  <ErasableTextField
                    id="school_granting_credit"
                    label="School Granting Credit*"
                    fullWidth
                    isRequired
                    type="text"
                    hasError={
                      methods.formState.errors?.school_granting_credit && true
                    }
                  />

                  {methods.formState.errors?.school_granting_credit &&
                    renderError(
                      methods.formState.errors?.school_granting_credit.message
                    )}
                </Box>
                <Box sx={addTranscriptRecordSelect}>
                  <FormControl
                    fullWidth
                    sx={addTranscriptFormSubject}
                    error={methods.formState.errors?.grade_level && true}
                  >
                    <InputLabel id="grade_level" shrink sx={bgWhite}>
                      Grade Level*
                    </InputLabel>
                    <Select
                      labelId="grade_level*"
                      id="grade_level"
                      defaultValue={defaultValues.grade_level}
                      {...methods.register("grade_level", {
                        required: requiredMessage,
                      })}
                    >
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                    </Select>
                  </FormControl>

                  {methods.formState.errors?.grade_level &&
                    renderError(methods.formState.errors?.grade_level.message)}
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={addTranscriptKlassName}>
                  <ErasableTextField
                    id="instructor"
                    label="Instructor"
                    fullWidth
                    type="text"
                    hasError={methods.formState.errors?.instructor && true}
                  />

                  {methods.formState.errors?.instructor &&
                    renderError(methods.formState.errors?.instructor.message)}
                </Box>
                <Box sx={addTranscriptRecordSelect}>
                  <FormControl fullWidth sx={addTranscriptFormSubject}>
                    <InputLabel id="department_id" shrink sx={bgWhite}>
                      Department
                    </InputLabel>
                    <Select
                      labelId="department_id"
                      id="department_id"
                      defaultValue={defaultValues.department_id}
                      {...methods.register("department_id")}
                    >
                      {departments.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={addTranscriptRecordSelect2}>
                  {!notNadCourse ? (
                    <FormControl
                      fullWidth
                      sx={addTranscriptFormSubject2}
                      error={methods.formState.errors?.course_id && true}
                    >
                      <InputLabel id="course_id" shrink sx={bgWhite}>
                        Course*
                      </InputLabel>
                      <Select
                        labelId="course_id"
                        id="course_id"
                        defaultValue={defaultValues.course_id}
                        {...methods.register("course_id", {
                          required: requiredMessage,
                        })}
                      >
                        {courses.map((course) => (
                          <MenuItem key={course.id} value={course.id}>
                            {course.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {methods.formState.errors?.course_id &&
                        renderError(
                          methods.formState.errors?.course_id.message
                        )}
                    </FormControl>
                  ) : (
                    <>
                      <ErasableTextField
                        id="course_name"
                        label="Course"
                        fullWidth
                        isRequired
                        type="text"
                        hasError={methods.formState.errors?.course_name && true}
                      />
                      {methods.formState.errors?.course_name &&
                        renderError(
                          methods.formState.errors?.course_name.message
                        )}
                    </>
                  )}
                </Box>
                <Box>
                  <FormControl fullWidth sx={formCheckBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...methods.register("not_nad_course")}
                          checked={notNadCourse}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={<Typography>Not NAD Course</Typography>}
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={addTranscriptRecordSelect2}>
                  <FormControl
                    fullWidth
                    sx={addTranscriptFormSubject2}
                    error={methods.formState.errors?.state_id && true}
                  >
                    <ErasableTextField
                      id="state_id"
                      label="State ID"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.state_id && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.state_id &&
                    renderError(methods.formState.errors?.state_id.message)}
                </Box>
                <Box>
                  <FormControl fullWidth sx={formCheckBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={defaultValues.transfer_credit}
                          {...methods.register("transfer_credit", {})}
                        />
                      }
                      label={<Typography>Transfer Credit</Typography>}
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={formTitle}>
                Academic Credits, Grades, and GPA
              </Typography>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={classNameTranscriptRecord}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.class_name && true}
                  >
                    <ErasableTextField
                      id="class_name"
                      label="Class Name*"
                      fullWidth
                      isRequired
                      type="text"
                      hasError={methods.formState.errors?.class_name && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.class_name &&
                    renderError(methods.formState.errors?.class_name.message)}
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={[formTitle, textUpper]}>Semester 1</Typography>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_grade"
                      label="Grade"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "20px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_percent"
                      label="Percent"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_credits_earned"
                      label="Credits"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_credits_possible"
                      label="Cred. Poss."
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_average"
                      label="Average"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_gpa"
                      label="GPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "20px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s1_ugpa"
                      label="UGPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={[formTitle, textUpper]}>Semester 2</Typography>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.s2_grade && true}
                  >
                    <ErasableTextField
                      id="s2_grade"
                      label="Grade"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.s2_grade && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.s2_grade &&
                    renderError(methods.formState.errors?.s2_grade.message)}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "20px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.s2_percent && true}
                  >
                    <ErasableTextField
                      id="s2_percent"
                      label="Percent"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.s2_percent && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.s2_percent &&
                    renderError(methods.formState.errors?.s2_percent.message)}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.s2_credits_earned && true}
                  >
                    <ErasableTextField
                      id="s2_credits_earned"
                      label="Credits"
                      fullWidth
                      type="text"
                      hasError={
                        methods.formState.errors?.s2_credits_earned && true
                      }
                    />
                  </FormControl>
                  {methods.formState.errors?.s2_credits_earned &&
                    renderError(
                      methods.formState.errors?.s2_credits_earned.message
                    )}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={
                      methods.formState.errors?.s2_credits_possible && true
                    }
                  >
                    <ErasableTextField
                      id="s2_credits_possible"
                      label="Cred. Poss."
                      fullWidth
                      type="text"
                      hasError={
                        methods.formState.errors?.s2_credits_possible && true
                      }
                    />
                  </FormControl>

                  {methods.formState.errors?.s2_credits_possible &&
                    renderError(
                      methods.formState.errors?.s2_credits_possible.message
                    )}
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s2_average"
                      label="Average"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s2_gpa"
                      label="GPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "20px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="s2_ugpa"
                      label="UGPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={[formTitle, textUpper]}>Final Grade</Typography>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.final_grade && true}
                  >
                    <ErasableTextField
                      id="final_grade"
                      label="Grade"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.final_grade && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.final_grade &&
                    renderError(methods.formState.errors?.final_grade.message)}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "20px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={methods.formState.errors?.final_percent && true}
                  >
                    <ErasableTextField
                      id="final_percent"
                      label="Percent"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.final_percent && true}
                    />
                  </FormControl>

                  {methods.formState.errors?.final_percent &&
                    renderError(
                      methods.formState.errors?.final_percent.message
                    )}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={
                      methods.formState.errors?.final_credits_earned && true
                    }
                  >
                    <ErasableTextField
                      id="final_credits_earned"
                      label="Credits"
                      fullWidth
                      type="text"
                      hasError={
                        methods.formState.errors?.final_credits_earned && true
                      }
                    />
                  </FormControl>
                  {methods.formState.errors?.final_credits_earned &&
                    renderError(
                      methods.formState.errors?.final_credits_earned.message
                    )}
                </Box>
                <Box sx={{ ...gradeTranscriptRecord, mr: "25px" }}>
                  <FormControl
                    fullWidth
                    sx={classNameTranscriptRecord}
                    error={
                      methods.formState.errors?.final_credits_possible && true
                    }
                  >
                    <ErasableTextField
                      id="final_credits_possible"
                      label="Cred. Poss."
                      fullWidth
                      type="text"
                      hasError={
                        methods.formState.errors?.final_credits_possible && true
                      }
                    />
                  </FormControl>

                  {methods.formState.errors?.final_credits_possible &&
                    renderError(
                      methods.formState.errors?.final_credits_possible.message
                    )}
                </Box>
              </Stack>
            </Box>

            <Box sx={[klassFormRow, transcriptDivider]}>
              <Stack direction="row">
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="final_average"
                      label="Average"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="final_gpa"
                      label="GPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...gradeGPARecord, mr: "20px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="final_ugpa"
                      label="UGPA"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box sx={{ ...creditsEarnedTranscriptRecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="absent"
                      label="Absent"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
                <Box sx={{ ...creditsEarnedTranscriptRecord, mr: "25px" }}>
                  <FormControl fullWidth sx={classNameTranscriptRecord}>
                    <ErasableTextField
                      id="tardy"
                      label="Tardy"
                      fullWidth
                      type="text"
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={formTitle}>Options</Typography>
            </Box>

            <Box sx={klassFormRow}>
              <Stack direction="row">
                <Box
                  sx={{
                    ...creditsEarnedTranscriptRecord,
                    mr: "20px",
                    display: "flex",
                  }}
                >
                  <FormControl sx={formCheckBox} style={{ width: "55%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={defaultValues.is_passing}
                          {...methods.register("is_passing", {})}
                        />
                      }
                      label={<Typography>Is passing</Typography>}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={formCheckBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={defaultValues.transfer_credit}
                          {...methods.register("calculate_in_transcript", {})}
                        />
                      }
                      label={<Typography>Calculate in transcript</Typography>}
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box sx={klassFormRow}>
              <Typography sx={formTitle}>Notes</Typography>
            </Box>

            <Box sx={klassFormRow}>
              <TextEditor
                value={defaultValues?.notes}
                onChange={(data) => onNotesChange(data)}
                isSimplified
              />
            </Box>
          </Box>
        </Box>
      </form>
      <AlertDialog
        isOpen={alertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleConfirm={deleteTranscript}
      />
    </FormProvider>
  );
}
