import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Stack,
  Button,
  Typography,
  CircularProgress,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  loader,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import {
  createGraduationPlan,
  fetchGraduationPlan,
  updateGraduationPlan,
  deleteGraduationPlan,
} from "../../../service/graduationPlanService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { CommonContext } from "../../../context/CommonContext";

export default function GraduationPlanForm({ planId, handleDrawer }) {
  const commonContext = useContext(CommonContext);
  const snackbarContext = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [plan, setPlan] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const saveRequest = (data) =>
    planId ? updateGraduationPlan(planId, data) : createGraduationPlan(data);

  const onSubmit = (formData) => {
    setLoading(true);

    saveRequest({ graduation_plan: formData })
      .then(() => {
        handleDrawer(false);
        commonContext.fireProcessStarted();
        snackbarContext.setSnackbar({
          message: "Graduation plan saved.",
          severity: "success",
          open: true,
        });
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
        Object.keys(data).map((key) =>
          setError(key, { type: "custom", message: data[key] })
        );
      });
  };

  const fetchPlan = (id) => {
    setLoading(true);

    fetchGraduationPlan(id)
      .then((response) => {
        const { data } = response;
        setPlan(data);
        Object.keys(data).map((key) => setValue(key, data[key]));
        setLoading(false);
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to fetch graduation plan.",
          severity: "error",
          open: true,
        });
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);

    if (confirmed) {
      setLoading(true);

      deleteGraduationPlan(planId)
        .then(() => {
          handleDrawer(false);
          commonContext.fireProcessStarted();
          snackbarContext.setSnackbar({
            message: "Graduation plan deleted.",
            severity: "success",
            open: true,
          });
        })
        .catch(() => {
          snackbarContext.setSnackbar({
            message: "Failed to delete graduation plan.",
            severity: "error",
            open: true,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (planId) fetchPlan(planId);
  }, [planId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>
          {planId ? "Edit" : "Add"} graduation plan
        </Typography>

        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawer(false)}>
            Cancel
          </Button>

          {plan && plan.all_students_count === 0 && (
            <Button sx={formButtons} onClick={handleDelete}>
              Delete
            </Button>
          )}

          {plan && plan.all_students_count > 0 && (
            <Tooltip title="Cannot delete graduation plan with students">
              <Button sx={{ ...formButtons, cursor: "not-allowed" }}>
                Delete
              </Button>
            </Tooltip>
          )}

          <GenericSubmitButton text="Save" submittingText="Saving..." />
        </Stack>
      </Stack>

      <Box sx={formContainer}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      typeof watch("active") === "boolean"
                        ? watch("active")
                        : true
                    }
                    color="info"
                    {...register("active")}
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Name"
              variant="outlined"
              {...register("name", { required: "Name is required" })}
              error={Boolean(errors?.name)}
              helperText={errors?.name && errors.name.message}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              multiline
              rows={6}
              {...register("description")}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={4}>
            <FormControl required fullWidth error={Boolean(errors?.plan_type)}>
              <InputLabel>Type</InputLabel>

              <Select
                value={watch("plan_type") || ""}
                label="Type"
                {...register("plan_type", { required: "Type is required" })}
                onChange={(e) => setValue("plan_type", e.target.value)}
              >
                <MenuItem value="type_1">Type 1</MenuItem>
                <MenuItem value="type_2">Type 2</MenuItem>
                <MenuItem value="type_3">Type 3</MenuItem>
              </Select>

              {errors.plan_type && (
                <FormHelperText>{errors.plan_type.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this graduation plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
