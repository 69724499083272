import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Stack,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  selectLabel,
  pinCheckBox,
  internalNoteBackground,
  internalNoteComment,
} from "./EventsForm.style";
import {
  mb25,
  mt35,
  danger,
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  formButtonsContainer,
} from "../../sharedStyles";
import ErasableTextField from "../../ErasableTextField";
import studentEventCategoriesService from "../../../service/studentEventCategoriesService";
import AlertDialog from "../../AlertDialog";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import GenericSubmitButton from "../../GenericSubmitButton";

export default function EventsForm({
  setIsEventDrawerOpen,
  onSubmit,
  currentEvent,
  handleEventDelete,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [categories, setCategories] = useState([]);
  const [isDialogOpen, setDialogState] = React.useState(false);
  const [actionTakenDate, setActionTakenDate] = React.useState(
    currentEvent?.action_date || null
  );

  const requiredMessage = "This field is required.";

  const getCategories = async () => {
    const response = await studentEventCategoriesService.fetchAllCategories();
    if (response.data) {
      setCategories(response.data);
    }
  };

  const renderError = (message) => (
    <Typography sx={danger}>{message}</Typography>
  );

  const handleClose = () => {
    setDialogState(false);
  };

  const deleteEventAlert = () => {
    setDialogState(true);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const { hasPermission } = useContext(PermissionsContext);

  const manageStudentEventPermission = hasPermission(
    APP_PERMISSIONS.STUDENT_EVENTS,
    PERMISSION_TYPES.MANAGE
  );

  const allEventsPermission = hasPermission(
    APP_PERMISSIONS.ALL_EVENTS,
    PERMISSION_TYPES.MANAGE
  );

  const managePermission = manageStudentEventPermission || allEventsPermission;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" sx={formHeader}>
        <Grid container item sm={5}>
          <Typography sx={formTitle}>
            {currentEvent ? "Edit Event" : "Add Event"}
          </Typography>
        </Grid>
        <Grid container item sm={7}>
          <Stack sx={formButtonsContainer} direction="row">
            <Button
              sx={formButtons}
              onClick={() => setIsEventDrawerOpen(false)}
            >
              Cancel
            </Button>
            {currentEvent && managePermission && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => deleteEventAlert()}
              />
            )}
            {managePermission && (
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            )}
          </Stack>
        </Grid>
      </Stack>
      <Box sx={formContainer}>
        <Stack direction="row">
          <FormControl fullWidth>
            <InputLabel id="event_category" shrink sx={selectLabel}>
              Select Category
            </InputLabel>
            {categories.length > 0 ? (
              <Select
                labelId="event_category"
                id="event_category"
                defaultValue={
                  currentEvent?.student_event_category_id || categories[0].id
                }
                displayEmpty
                {...register("event_category", {
                  required: requiredMessage,
                })}
                disabled={!managePermission}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <p>Loading...</p>
            )}
          </FormControl>

          <FormControl fullWidth sx={pinCheckBox}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={currentEvent?.is_pinned || false}
                  {...register("is_pinned", {})}
                  disabled={!managePermission}
                />
              }
              label={<Typography>Pin event</Typography>}
            />
          </FormControl>
        </Stack>

        <Box sx={mt35}>
          <Box sx={mb25}>
            <ErasableTextField
              id="comment"
              label="Comment*"
              fullWidth
              isRequired
              isMultiline
              lines={5}
              defaultValue={currentEvent?.comment}
              hasError={errors?.comment && true}
              disabled={!managePermission}
            />

            {errors?.comment && renderError(requiredMessage)}
          </Box>

          <Box sx={mb25}>
            <ErasableTextField
              id="event_action"
              label="Actions Needed/Taken"
              fullWidth
              hasError={false}
              isMultiline
              lines={5}
              defaultValue={currentEvent?.action}
              disabled={!managePermission}
            />
          </Box>

          <Box sx={mb25}>
            <ErasableTextField
              id="internal_notes"
              label="Internal Note"
              fullWidth
              hasError={false}
              isMultiline
              lines={5}
              defaultValue={currentEvent?.internal_notes}
              disabled={!managePermission}
              inputSx={internalNoteBackground}
            />
            <Typography sx={internalNoteComment}>
              Internal note is only visible to administrators and author.
            </Typography>
          </Box>
          <Box>
            <DesktopDatePicker
              id="action_date"
              label="Action Needed or Token Date"
              inputFormat="MM/DD/YYYY"
              onChange={(date) => {
                if (date) {
                  setActionTakenDate(date);
                  setValue("action_date", date.format("MM/DD/YYYY"), {
                    shouldDirty: true,
                  });
                }
              }}
              value={actionTakenDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.action_date)}
                  {...register("action_date", {
                    pattern: {
                      value:
                        /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                      message: "Invalid date format.",
                    },
                  })}
                />
              )}
              disabled={!managePermission}
            />
            {errors.action_date && (
              <Typography sx={danger}>{errors.action_date.message}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        handleConfirm={handleEventDelete}
      />
    </form>
  );
}
