import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import moment from "../../../utils/constants/momentConfig";
import studentsService from "../../../service/studentsService";
import {
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  rightDrawerContainer,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function EnrollmentsVersion({ setEnrollmentsVersionOpen }) {
  const params = useParams();
  const [enrollmentsHistory, setEnrollmentsHistory] = useState([]);
  const methods = useForm({ mode: "onChange" });
  const { setSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchEnrollmentHistory = async () => {
      const history = await studentsService.fetchEnrollmentsHistory(
        params.student_slug,
        {
          school_id: params.school_id,
        }
      );
      setEnrollmentsHistory(history.paired_enrollments);
    };

    fetchEnrollmentHistory();
  }, [params.student_slug, params.school_id]);

  const isDateWithinSchoolYear = (date, schoolYear) => {
    if (
      !date ||
      !schoolYear ||
      !schoolYear.start_date ||
      !schoolYear.end_date
    ) {
      return false;
    }

    const dateToCheck = moment(date);
    const startDate = moment(schoolYear.start_date);
    const endDate = moment(schoolYear.end_date);

    const result = dateToCheck.isBetween(startDate, endDate, "day", "[]");

    return result;
  };

  const handleDateChange = (index, newDate) => {
    const updatedHistory = [...enrollmentsHistory];
    const formattedDate = newDate ? newDate.toISOString() : null;
    updatedHistory[index].enrolled.date = formattedDate;
    updatedHistory[index].enrolled.dateError =
      formattedDate &&
      !isDateWithinSchoolYear(
        formattedDate,
        updatedHistory[index].enrolled.school_year
      )
        ? `Date must be within the school year.`
        : "";
    setEnrollmentsHistory(updatedHistory);
  };

  const handleWithdrawalDateChange = (index, newDate) => {
    const updatedHistory = [...enrollmentsHistory];
    const formattedDate = newDate ? newDate.toISOString() : null;
    updatedHistory[index].withdrawn.date = formattedDate;
    updatedHistory[index].withdrawn.dateError =
      formattedDate &&
      !isDateWithinSchoolYear(
        formattedDate,
        updatedHistory[index].withdrawn.school_year
      )
        ? `Date must be within the school year.`
        : "";
    setEnrollmentsHistory(updatedHistory);
  };

  const onSubmit = () => {
    if (
      enrollmentsHistory.some(
        (enrollment) =>
          !enrollment.enrolled.date ||
          enrollment.enrolled.dateError ||
          (enrollment.withdrawn && !enrollment.withdrawn.date)
      )
    ) {
      setSnackbar({
        message: "Please fill out all required fields.",
        open: true,
        severity: "error",
      });
      return;
    }
    studentsService.updateEnrollmentHistory(
      params.student_slug,
      enrollmentsHistory
    );
    setEnrollmentsVersionOpen(false);
    setSnackbar({
      message: "Enrollments history updated",
      open: true,
      severity: "success",
    });
  };

  return (
    <Box sx={rightDrawerContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>History of enrollment</Typography>
            <Stack direction="row">
              <Button
                sx={formButtons}
                onClick={() => setEnrollmentsVersionOpen(false)}
              >
                Cancel
              </Button>
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Stack>
          <Box sx={formContainer}>
            <Grid container sx={{ mb: 3 }}>
              {enrollmentsHistory.map((enrollment, index) => (
                <Grid container item xs={12} sx={{ mb: 3 }}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      label="Enrollment Date"
                      inputFormat="MM/DD/YYYY"
                      value={
                        enrollment.enrolled?.date
                          ? moment.utc(enrollment.enrolled?.date)
                          : null
                      }
                      onChange={(newDate) => handleDateChange(index, newDate)}
                      renderInput={(renderParams) => (
                        <TextField
                          {...renderParams}
                          required
                          error={
                            !enrollment.enrolled?.date ||
                            Boolean(enrollment.enrolled?.dateError)
                          }
                          helperText={
                            !enrollment.enrolled?.date
                              ? "This field is required."
                              : enrollment.enrolled?.dateError
                          }
                          sx={{ width: "90%" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      label="Withdrawn Date"
                      inputFormat="MM/DD/YYYY"
                      value={
                        enrollment.withdrawn?.date
                          ? moment.utc(enrollment.withdrawn?.date)
                          : null
                      }
                      onChange={(newDate) =>
                        handleWithdrawalDateChange(index, newDate)
                      }
                      disabled={!enrollment.withdrawn}
                      renderInput={(renderParams) => (
                        <TextField
                          {...renderParams}
                          required
                          error={
                            Boolean(enrollment.withdrawn) &&
                            Boolean(!enrollment.withdrawn.date)
                          }
                          sx={{ width: "90%" }}
                          helperText={
                            Boolean(enrollment.withdrawn) &&
                            Boolean(!enrollment.withdrawn.date)
                              ? "This field is required."
                              : null
                          }
                        />
                      )}
                      clearable
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
